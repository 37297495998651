<template lang="pug">
  .hello
    .ui.container.segment
      img.floated.left(src="../assets/隨緣自強.jpg")

      h2 自主學習實驗計畫的緣起
     
      p 對自主學習實驗計畫來說，「自主學習」不是學習方式的一種，也不單純是一種知識或技藝的學習，而是一種生命態度的實踐與學習。也就是希望身處其中的成人或學生，都能學習對自己的生命負責。了解自己、了解環境，並在其間尋求自己與自己、自己與環境間的最佳互動可能。因此，這樣的學習，不可能只在課堂上，用傳統的講述方式教給學生，而必須讓學生和所有成員，透過生活上的點滴對應，相互討論、相互影響、相互折衝而得。

      p 自主學習實驗，根據學生的身心發展，配合國家中小學教育的階段思考，而有中學、小學階段的實驗。此項實驗強調參與的親、師、生均能以平等互動的方式，決定學校的政策。學習上「以學生為中心」，希望將學習權還給學生，並落實尊重個性和差異的因才施教理念；生活上採「師生共治方式」，以生活會議、生活公約和師生法庭落實此項理想；教師教學自主的設計，也使傳統教師得以脫離原有桎梏，重新發揮創意；而「家長參與、親師協同、討論文化」的建立，更使親、師、生間得以誠實互動，相互支援。這種從觀念到組織，從課程到生活的整體運作模式，所建立的校園新文化，完全符合當前教改「九年一貫課程精神」、「學校本位」、與「開放教育」的典例，並為未來十二年國教做準備。

      p 民國八十三年起，「財團法人毛毛蟲兒童哲學文教基金會」、「財團法人兒童教育實驗文教基金會」在台北縣莒光國小、烏來國中小信賢分部進行國小階段的實驗，迄今八年，成效良好，被譽為台灣教改的希望工程。

      p 中學階段的實驗，由「財團法人兒童教育實驗文教基金會」自民國八十七年起在台北市北政國中進行三年。因「六年一貫」之學程尚未結束，台北市教育局為貫徹教改決心，決定自九十學年度起，以維持原有三班，不再續招新生之方式，概括承接「北政國中自主學習實驗計畫」的後續工作，易地至景文高中，繼續以公民合作方式完成原有計畫。

      p 此計畫已被聯合國教科文組織負責另類教育研究的學者譽為「亞洲最好的另類教育之一」，也是台灣「中學生教改聯盟」認為最尊重學生權益的校園典範，更是教育部、法務部認為真正落實民主法治教育的學校。

      p 無論是中學部或小學部的自主學習，之所以出現和存在，是靠所有參與親、師、生的教育信念而來。因此，有人說它是個真實的教育之夢，我們希望有朝一日它能成為教育的真實。

      h2 自主學習實驗計畫理念與目標

      h3 自主學習實驗計畫理念與立論基礎

      p 二十一世紀是個資訊爆炸，科技發達、社會快速變遷，國際關係日益密切的新時代。因此教育是開展學生潛能、培養學生適應與改善生活環境的歷程。 
 
      p 「自主學習」係參照世界先進國家教育發展之理論與實務，以人本主義的哲學思考，進步主義的教育思想，建構主義的學習概念，以及現有民主國家分權與制衡架構，所設計出的一種有機式的校園運作模式。其形成深受西方一百年來另類教育學（alternative education）的理念啟示，學理依據與另類教育學之共同精神並無二致，即（What educational alternatives generally have in common with each other is an approach that is more individualized, has more respect for the student, parent, and teacher, and is more experiential and interest-based.）（Mintz, 1994, XI）。

      p 學者歸納「另類教育學」理念共同之特色如下：
      p 崇尚自由思想（free thinking），不受制式教育的拘限。
      p ※ 強調教育的倫理在於適應學生的需求，而非使學生適應學校的要求。
      p ※主張教學活動以學生的興趣為導向，學習內容與實際生活相結合。
      p 重視建構性（constructive）及啟發性（initiative）教學，幫助學生從遊戲－活動－工作中學習。
      p 重視人文主義（humanistic education）、人權教育（human rights education）及全人教育（whole-person education）。
      p 實踐人本教育與民主法治教育，絕不使用體罰，培養學生健康之自尊心與道德自律涵養。
      p ※實踐「我－汝」（I-Thou）平等精神之師生關係，揚棄傳統威權的師生倫理。
      p ※鼓勵家長參與學校相關事務，共同負起孩子的教育責任，也分享孩子學習與成長的喜悅。在共同組成的「民主社群」中實踐民主教育、人性教育。
      p 拒絕成就壓力與惡性競爭壓力，鼓勵學生自動自發的學習與自我教育。
      p 放棄各種量化/排名的學習成就評量，採用文字敘述/個別性的學習診斷報告。
      p 學生之分班分組採彈性化原則，但在學習過程中強調團隊合作精神。
      p 強調提供學生充分自主的學習空間，並學習對自己負責。
      p 逐步取消科目間的界限，課程設計則融入自然環境及生活體驗。
      p 普遍消除以教師為中心的教學活動，改以學生為本位的學習活動。
      p 鼓勵學生創意的表達。
      p 教師在教學中扮演指導者、諮商者與協調者的角色。

      p 如同德國著名教育學者Theoder F. Klaßen 及Ehrenhard Skiera 在『歐洲教育改革與另類學校手冊』一書中（Handbuch der reformpädagogischen und alternativen Schulen in Europa）指出另類學校的最重要理念與價值有四項：（1）受教者中心取向（Pädozentrische Orientierung）、
      p （2）完整而全方位的教育（Ganzheitliche und vielseitige Erziehung）、
      p （3）自治化學校社區思維（Gedanke der selbstverwalteten Schulgemeinde）、（4）互助合作的社群教育（Gemeinschaftserziehung durch Kooperation）。
      p 其與教育改革發展之關係如下：
      p 提供體制教育自省的刺激、
      p 樹立體制教育的參考典範、
      p 催化教育典範的演化速度、
      p 維護教育本質的理想、
      p 提供教育多元選擇的機會。

      p 總之，自主學習教育的基本理念在於：教育乃是「成人之美」，是幫助學生免於恐懼的學習，使其自由與成熟，綻放於愛及良善之中。（依教育局再審意見修正及補充）

      h3 自主學習實驗計畫目標

      p 十三至十八歲的青少年，是人格養成的重要時期。因此自主學習實驗計畫希望透過校園文化的情境塑造，讓學生從切身參與中，培養下列特質的良性公民：

      p 了解自己的性向，發現自己的興趣，能夠運用資源，具有成就自我的意願與能力。

      p 培養日後獨立生活之基本知識與技能，以及進一步從事學術研究的知識與能力。

      p 尊重他人，具有自我管理能力、與人從事良好互動的合作能力。

      p 透過自我發展，培養學生在多元社會價值衝擊下，保有獨立判斷和理性選擇的能力，培養社區意識、人文情懷與國際視野。


                                       

      h2 三、自主學習實驗計畫組織運作圖(略)



      h3 自主學習實驗計畫行政架構圖(略)



      p 行政組識與行政架構的補充說明：

      p 參與人員
      p 角色、分工與權責
      p 主持人

      p 1.對外代表實驗計畫，協調主管機關與合作單位
      p 2.對內協調親、師、生三大團體，整合行政單位，支援教師教學與學生學習
      p 3.運籌維握實驗計畫之相關業務與資源運用
      p ★主持人由基金會聘任。
      p 顧問
      p       1.應實驗計畫之請，提供各項教育現況與環境互動之諮詢
      p 2. 應實驗計畫之請，指導實驗計畫課程發展與教材研發
      p 3.觀察實驗計畫教育目標與精神之落實情況
      p 教師團
      p 成立各科研究會，落實實驗計畫目標
      p 成立成長團體，追求專業成長
      p 協同家長團體，維護學生權益
      p 行政單位
      p 1.處理教學及學生學習事務
      p 2.提供學生輔導及生涯諮詢
      p 3.維護實驗計畫之設備與資源
      p 4.建立特教鑑輔制度
      p 
      p 實驗計畫與各合作學校之關係，見其合約。


      h2 自主學習實驗計畫實施策略與決策機制

      p 自主學習相信「自由產生創意」，「自主才有意義」，因此，自主學習實驗計畫不只提供學生自主學習的空間，更保障教師的創造可能，希望生活其間的成人，以自我典例，提供學生良好學習的範例。
      p 因此，自主學習實驗計畫即在塑造一個安全、誠實、支持的環境，讓生活其間的每一個人，都有機會做他真心想做的事。並在成員坦率明朗的互動中，得到自我發展的最佳可能。

      h3 自主學習實驗計畫實施策略：

      p （一）以學習型組織取代階層化的運作方式 
      p 學生、教師、家長三大團體平等互動的學習型組織，及行政支援系統的運作，確保校園活潑的創造可能。
      p （二）以自然權威取代傳統制度性的威權可能
      p 在實驗計畫中人人平等，生活中不因身份、地位而有制度性的威權存在，學習及工作中，則有自然權威的存在。
      p （三）資訊的透明與流通
      p 實驗計畫採直接民主制，生活會議是實驗計畫內部最高權力單位，因此所有實驗計畫相關資訊皆需透明、流通，以確保成員在參與決策時的清明。
      p （四）建立良好的討論文化
      p 「建立良好的討論文化」是實驗計畫的重要目標，因此，不但在必修課程「學習理論與方法」中，明確釐清溝通的定義、要素，選修課中開設情緒認知、家庭溝通、人際溝通、讀書會、哲學教室等課程，各科教學也多採討論方式進行。並在日常生活中，提供各項討論機制。如全校性的生活會議、班級性會議、輔導諮商、各項談話會；親師間期初、期中、期末的定期會談；不定時的親師溝通談話等等。務使「討論」成為實驗計畫的生活方式。
      p （五）提供自願參與的機會
      p 無論教職員生、家庭或相關人員，實驗計畫都希望他們的加入是自願的。如果覺得此處不合適自己，實驗計畫也提供隨時離開的協助和可能。

      p 自主學習實驗計畫決策機制如下：
      p 師生生活會議為校園議事的最高決策機構
      p 教師會決定教師相關事務
      p 學生會決定學生相關事務
      p 家長會決定家長相關事務
      p 行政單位決定行政相關事務
      p 如有爭議，由主持人召集協調會處理。

      p 自主學習實驗計畫學生學習領域

      p 自主學習中學階段的學習，與國家中學階段的學習領域並無不同，亦包含個體發展、社會文化及自然環境等三個面向，提供下列七大領域的學習：

      p 語文（包括必修的中文、英文，及選修的第一外文英文、第二外文／日文、第三外文等）

      p 健康與體育（包括必修的健康與性、體適能、游泳、田徑，及選修的各項體育科目等）

      p 社會（包括必修的公民與社會、史地，與選修的各項科目，和生活會議、法庭等潛在性課程的學習）

      p 藝術與人文（包括必修的群體美育、美學欣賞專題、及選修中各項文史哲和藝術性課程。）

      p 數學（包括必修的算學、數學，及選修的各項數學類課程）

      p 自然與科技（包括必修的基礎科學、電腦資訊及選修中的各項物理、化學、生物等相關課程）

      p 綜合活動（包括必修的學習理論與方法、食衣住行基本生活能力，每學期定期舉行的大旅行、劇團公演、樂音行雲音樂會，及各項運動會、園遊會等。各科並視學習需要，獨立或協同舉辦校內外的學習活動。）

      p 自主學習實驗計畫各科教學研究會將視已身之能力，逐步聘請教學顧問，改進各科課程設計及教學。











      h2 自主學習實驗計畫六年一貫學程設計

      p （一）原則

      p 實驗計畫採六年一貫制的三學程設計。其原因在十三歲到十八歲的青少年，正處於生理上的青春期和心理上自我認同期的雙重轉變。加上他們從小學傳統、他律的被動學習到中學自律的主動學習，一定要經歷學習困惑、自我反省、概念重建的內在革命歷程。因此，實驗計畫提供「必修」到「選修」到「自主」的三學程思考，以逐步建立學生自我負責的能力，

      p 其中，必修學程主要是提供學生未來二十一世紀公民必備的生存能力；選修學程則提供學生各項優勢能力的試探可能，直至學生具有自我了解、運用資源、與環境良好互動的能力後，再根據個人的自我期許，因應未來生涯規畫，而有自主學習學程。

      p 因此，自主學習六年一貫的三學程規畫，對學生的學習來說，是難以分割的完整學習歷程。其目的在強調學習者自發性之學習發展，教育乃是一種「助人」的藝術，其意義在於幫助學生經由「自我探索」（self-inquiry）、「自我發現」（self-discovery）、最終而能「自我完成」（self-fulfillment）。




        h2 學程架構及說明



      p 一至四年

      p 必修
      p 基本核心能力
      p （課程內容預計兩年內可修完） 

      p 選修
      p 個人優勢能力
      p （最低60學分預計兩年內可修完） 



      p 內容

      p 1.食、衣、住、行基本生活能力
      p 2.青少年法律 
      p 3.中文
      p 4.英文
      p 5.算學 （國中數學）
      p 6.基礎科學
      p 7.健康與性
      p 8.史地
      p 9.資訊科學
      p 10.美學
      p 11.體育
      p 12.學習理論與方去

      p 內容
      p 學校選修課程應包括：語文、數學、自然與科技、藝術、社會、健康與體育、綜合活動等七大領域。
      p 學校應開設各學科之專精課程與通識課程，供學生選修。
      p 鼓勵學生籌組社團，並按社團績效核給學分。
      p 允許學生從事校內外之主題學習，以因應學生特殊學習需求。


      p 備註

      p 必修學程應視學生實際需要，分級分組教學。
      p 必修之數學以國中程度為準。
      p 學習理論與方法除另開課外，並融入新生體驗及每學期期初、期末和大旅行等綜合活動中，全面落實。
      p 美學前兩年為群體美學，後兩年為中西美術與工藝、音樂、電影等藝術欣賞專題。
      p 體育科除前兩年為體適能、田徑及游泳外，第三、四年必選，第五、六年自選，以保持健全的體魄，養成規律的運動習慣。
      p 除特教學生外，必修課程不以分數計算成績，只有「過」與「不過」的及格考量。


      p 備註
      p 選修學程得與必修學程同步進行。
      p 社團及校外學習、主題學習之學分，由學生申請，經教師團討論後，決定授予。
      p 實驗計畫應予統整後，分級、分組開設教育部公布之國高中基礎課程。其深度、廣度等同國家標準，以利學生學習轉化。
      p 學生五人以上，得視實驗計畫負擔能力，申請開設特定課程。
      p 主題學習之精神應融入各科學習中，各科教師均應發展學生合作分享的、跨科際的主題研究能力。
      p 實驗計畫開課以核心選修課優先。
      p 實驗計畫應鼓勵服務性社團之成立，以培養學生對公共事務之參與精神。


      p 自主規畫學程
      p 升學或就業的準備期
      p （兩年）

      p 兩年以內
      p 內容學生得按自己的興趣，與未來升學或就業的生涯規畫，提出自主學程計畫自學計畫。
      p 自主學程計畫 應包括學習目標、達成方式、資源運用規畫等。
      p 達成計畫目標之學生，經學生畢業審查委員會審議通過後，即由實驗計畫轉請寄籍學校按正常程序 發給畢業證書。

      p 備註

      p 本學程得運用前二學程之學習資源，進行學習。
      p 學生從事自主學習之規畫與學習時，得申請學校支援。
      p 學校應妥善利用社區資源及校際合作方式，協助學生從事有效的學習。




      p （三）學程內容：
      p 領域
      p 科別

      p 課程名稱
      p 必選修
      p 預估選課年別
      p 備註
      p 必修
      p 核心選修
      p 選修

      p （四）學期開課課程

      p 實驗計畫各科課程綱要、目標、教材教法、課程內容、評量方式等，授權教師團依實驗計畫精神、學生實際需求、教師人力逐年修正。

      p 每學期之課程開設，詳見各學期課程規畫書。


      h2 各科教學規畫

      p 為求課程系統的連貫與統整，北政自主學習實驗計畫各領域教學研究會認同國中九年一貫及高中各科課程規畫之中心理念，並自發性地邀請學者專家，或由相關領域教師研究會，從事各項相關學科的教材教法研發工作：

      p （一）語文類：中文／政大中文系丁敏教授指導、中文科研究會
      p 英文／師大英文所周中天教授指導、英語科研究會

      p （二）健康體育：健康與性／師大衛教系晏涵文、劉貴雲、劉潔心教授合作開發
      p 體育 ／健康體育研究會

      p （三）社會：史地／政大歷史系彭明輝教授、劉祥光教授指導
      p 學習理論與方法、青少年法律／社會科研究會
      p 社會與人文核心選修課程／社會科研究會

      p （四）藝術：美育及各項藝術工藝課程／杜文仁先生指導
      p ／藝能科研究會

      p （五）數學：算學、國高中數學／台大朱建正教授指導、數學科研究會

      p （六）自然與科技：基礎科學及相關科學課程
      p ／中央研究院地科研究所劉啟清教授、師大黃芳裕教授指導、科學研究會
       p 電腦及資訊相關課程／資訊科學研究會

      p 綜合活動：導師課、大旅行、基本生活能力(食、衣、住、行)、發表會、 民俗節慶等活動／跨科整合

      p 詳細資料請見每學期教學規畫。


      h3 多元化的學科學習評量

      p 多元化的評量，是協助學生了解自我的重要機制。實驗計畫各科教學規畫中均明載該科評量程序及方法。綜合而言，實驗計劃所有課程之評量皆兼採文字性／質性方式，與百分比／計量評量，平時注重形成性之評量，並在期中與期末分別與家長討論學生之學習情形。各課程實際評量，則尊重授課教師之「專業自主權」，依其個別裁量進行。

      p 因此，實驗計畫採用的評量包括計質與計量的評量：

      p 計質評量包括：

      p 1.課堂形成性評量
      p 2.期中觀察報告
      p 3.期末評量報告
      p 4.學生作品

      p 計量評量包括：

      p 1.課堂成就評量
      p 2.必修科通過評量
      p 3.選修科學分取得評量
      p 4.國家基本學科能力檢測
      p 5.國際學科基本能力檢測
      p （目前有英國的Saxoncourt Test for Young Learners of English 與澳洲國際學科評量 International Assessments for Schools）

      h3 學生選課機制與輔導

      p 自主學習實驗計畫的學習特色包括：

      p 學程制，學生得按自己的學習速度進行。唯最長修業年限不得超過實驗計畫核定時限，年齡不得超過二十歲。
      p 學期必修科的排課時間不超過二十五堂，以保留學生選課及組織社團、自我學習的時間。
      p 修課程視學生實際修習意願與需求彈性開課。每學期調整一次。
      p 師團定期做課程規畫討論，以兼顧學科之縱深與橫向聯結之需求。
      p 重視學習品質，採不及格重修制。
      p 視學生生活及研究能力的培養。
      p 視個別化的學習滿足。
      p 鼓勵社團尤其是服務性社團學習。（學校並設服務護照，鼓勵學生參與公共事務）
      p 鼓勵主題學習與校外的個別主動學習。（包括各項學術競試、展覽活動等）
      p 推動校際合作（包括大學先修、校際選課、國內外交流學習）
      p （十一）進行社區合作
      p （十二）重視國際視野培養。（包括參與國際學科評量、參與國際性活動、海外遊學等）

      p 為協助學生良好選課及學習，實驗計畫提供下列機制：

      p 每學期開學前，學生即領得該學期詳明之課程規畫，其中詳細說明每一科目的類別、教師、課程目標、上課時數、授課對象、教材、教法、作業、評量方式、及注意事項，讓學生有機會明確了解每一科目之情況，方便選擇。
      p 每學期開學前舉辦親師座談，讓家長了解各科情況，希望家庭能夠協助學生良好選擇。
      p 每學期開學時還有一次對學生的課程說明會
      p 必修科或延續性課程，除以上學期成績為考量外，開學時並舉行分組檢測，協助學生選擇進入適合自己學習速度的組別。
      p 開學時有兩週的試課時間，兩周內均得加退選。
      p 導師及各科教師均負責提供選課諮詢。

      h2 十一、學生生活與輔導

      p （一） 生活
      p 實驗計畫對學生將以自律訓輔制度取代原有的他律設計。
      p 由師生共同參與的「生活會議」負責議決制度及研討生活中各項題，培養學生參與校務及民主議事的能力。
      p 由實驗計畫師生討論通過的「生活公約」（附件一）及「法庭制度」取代傳統校規，做為學生行為準則，處理常規問題。
      p 開放個人外表，由學生自行打理，列為美育重要項目。
      p 由學生自選導師，負責生活事項、選課計畫諮詢、心理輔導、親師溝通等事宜。
      p 家長應與學校共同培養學生的危險意識與自救能力，共負學生安全責任；學校應提供明確的校區活動區域和支援系統；學生應負責自己的身心安全。

      p （二）輔導
      p 1. 實驗計畫應設專人負責學生生涯規畫輔導、聯絡相關單位，及處理學生與現有學制不同評量判準所產生的連結需要，從事學生性向測驗，作為升學或就業的準備。
      p 2. 舉辦新生及轉學生試讀及體驗輔導
      p 3. 依教育局規定辦理特教生的鑑定與輔導，妥善利用特教系統和資源，協助特教學生學習，並輔導其生涯規畫。
      p 4. 期中、期末進行學生的學習評量（包括自評與教師評量），以了解學生學習情況
      p 5. 提供學生個別化的心理諮詢服務
      p 6. 辦理教職員進修，全面增進教職員輔導知能，並期許所有參與實驗計畫的教職員工均負學生輔導責任。
      p 每週舉辦教師會議，會商學生個案需求
      p 開設家長成長課程，加強親職教育。
      p 9. 落實親師協同，認輔制度，妥善利用家長及社區資源協助學生，以期達到絕不放棄任何一位學生的目標。
      p 10.舉辦各項輔導講座 

      p （三）生活評量
      p 生活評量以文字化的計質評量，取代傳統操行評分和功過獎懲，內容包括：
      p 法庭記錄
      p 輔導記錄
      p 期中教師觀察評量
      p 期末導師觀察評量
      p 學生自我反饋評量

      h2 十二、學生入學、轉學與畢業

      p （一）入學

      p 實驗計畫學生名額由台北市政府核定，入學辦法亦同。

      p （二）轉學

      p 本實驗計畫九十學年度，概括承續北政國中自主學習實驗計畫自願加入學生，如有餘額，得公開招收轉學生。
      p 為求六年一貫制之課程接續，及學生內在轉變的時間需求，實驗計畫只招收高一以下之轉學生。
      p 轉學生均需參加說明會，以確實了解實驗計畫內容。
      p 符合實驗計畫精神之轉學生超過可收名額時，以抽籤方式決定入學先後次序。
      p 實驗計畫轉學辦法由台北市教育局另案核定。

      p （三）與現有學制之轉換及畢業判準

      p 實驗計畫進行中若有學生轉出，學籍計算依照其修業年限；學力認定依學生實際修習情況，由實驗計畫出具證明，協助學生轉學。

      p （四）畢業判準

      p 實驗計畫學生完成必修學程十二門課程、選修學程六十（以上）學分、自主學程三階段之學習，且每學期未因違返生活公約被法官團判決回家反省五日（不含）以上者，實驗計畫應轉請寄籍學校按正常程序，發給高級中學畢業證書。成績不合前款規定者，發給修業證明書。


      p （五）自主學程學生如無法達成畢業目標，到前項第一款、第二目標準，經取得家長或監護人同意後，得申請延長修業年限，並應自行負擔相關費用。費用額度等同教育局對實驗計畫之學生補助標準。

      h2 十三、教師延聘與專業發展

      p 自主學習教師注重的。不只是學生的學習成就，更在個別學生教育目標的達成。因此維持良好的學習環境、提供所需的引介與資源，讓學生依照自己的速度，尋求最佳的學習方式，是非常重要的過程。


      h3 教師基本信念

      p 相信學生皆有學習及自我成就的潛能。
      p 教師應提供建議與方法，而非直接干涉學生的學習進路。
      p 教育應從學生當下之所在開始，而非以劃一的標準要求。
      p 學習應從行為的自然結果中獲得，而非以人為的不當方式達成。
      p 學習成果應從親身的體驗與感覺中產生，而年由灌輸或命令造成。

      p 本計畫將協助教師從傳統的權威角色中釋放，落實教學自主，並與學生共同參與校務，逐步達成師生治校的目的。

      h3 教師基本特質

      p 願意儘力協助學生找出他自己的長處，並支持學生從事各項成長努力。
      p 具有開放的心，願意在覺得有道理的情況下，改變自己原有判準。
      p 具有反省力，本身是個學習者，願意從環境、花草樹木、他人、特別是從學生身上學習，並且樂於與人合作學習。
      p 願意不用引起學生身心痛苦的手段，做為教學方法。
      p 具有一定的敏感度，能了解學生特質，並對家庭和學生提出積極性之建議。

      h3 專業能力

      p 對自己負責的課程具有一定的專業知識，並能整合相關知識領域，選擇適當教材，按學生身心發展程度，陪伴學生從事知識與情意方面的教育。
      p 具有相關教學技能，包括溝通技巧、引領學生專注學習的技巧、尋求有效評量的技巧、課室經營、個別指導、以及引發學生學習機與熱忱的能力。

      h3 互動能力

      p 能與學生從事非教學上的互動，從事親師溝通、並與教師團合作共事。
      p 能對學校整體氣氛營造有所貢獻，提出建設性的意見，從事自我進修，參與教學研討，並寫教學反省記錄。

      h3 職責分配

      p 教師團召集人

      p 編列預算、核對每月帳目。
      p 主持行政會議、協調教師之間的工作分配及教師進修事宜。
      p 主持親師會議，協助教師與家長溝通。
      p 對外代表實驗計畫，簽訂各項合作協約。如與其他單位合作，須報教育局備查。
      p 負責內部行政裁量。

      p 導師

      p 學生心理及生活輔導
      p 撰寫導師班學生期末生活觀察評量
      p 家長聯絡
      p 對學生及家長宣達校務事宜

      p 學科教師

      p 規畫課程，執行教學。
      p 批改學生作業。
      p 了解學生缺課原因，並視情況通知導師及家長。
      p 與導師及家長共同會商解決學生學習問題。
      p 撰寫期中、期末評量。

      p 開放學習區駐守教師

      p 經營開放學習區，使該區成為學生重要學習場所。
      p 制定並維持開放學習區規則，使學生能遵守規則，愛惜公物。
      p 開拓學生視野，注意學生性向，指導學生從事學習。
      p 應學生之請，進行師徒式的指導教學。

      p 社團指導教師

      p 1.開拓學生視野，注意學生性向，指導學生從事有意義的學習。
      p 2.協助學生經營社團，培養學生自發性的學習樂趣。
      p 3.評估社團學習成效，對教師團提供學分授與建議。

      p 進修

      p 實驗計畫視實際需要，提供教師進修機會，協助教師成為自主學習的適任教師。
      p 教師應參與每週的教師會議。
      p 參與各科教學研究會，相互看課堂，相互做支援教學、討論。
      p 參與知能手冊研發。
      p 撰寫教學反省記錄。
      p 教師需要協助時，得要求計畫主持人或邀請教學顧問參與課堂、提供建議。
      p 利用評鑑制度，協助教師發現自己的優點，並透過他人觀察，協助教師進步

      p 考核與評鑑

      p 1.期末教師自我反饋評鑑
      p 2.期末主持人觀察評鑑
      p 3.期末學生評鑑
      p 4.家長意見調查
      p 教育局觀察評鑑

      p 聘任與解聘

      p 實驗計畫教師團得視實驗計畫之需要，依照現行相關法令，秉持公開、民主、專業之原則，由基金會、教師、家長、學生與專家學者代表，組成「教師評審委員會」，聘用專任教師及兼任教師。

      p 計畫主持人透過教學評鑑制度，若對教師不滿，應提出具體事實之書面意見，要求教師提教學改善計畫。若教師在一定時間內無法與主持人達成協議，主持人得召開教師評議會，邀請家長、學生代表與會，並依教師法之規定，決定教師解聘與否。

      p 家長若對教師不滿，得向家長會提出不信任案，經家長會決議，送請主持人或教師團召集人，依教師法之規定處理。

      p 學生對教師不滿時，得向教師團召集人提出意見。召集人應視狀況與學生、教師溝通，或轉請計畫主持人處理。



      h2 十四、家長的角色與功能

      h3 基本信念

      p 作為子女生命的創造與支持者，家長在加入本實驗計畫時，應具有以下信念：
      p 接受子女本身獨特的價值與型態，而非視教育為達成父母目的之工具。
      p 提供經驗與子女分享，建立開放討論的家庭文化。
      p 與教師共同合作，面對子女的特殊需求。
      p 提供資源，協助實驗計畫運作。
      p ★本計畫將協助家長從傳統的威權角色中釋放，落實家庭民主，重建積極正面的親子互動模式。

      h3 基本定位

      p 家長是是學校順利推動教育的重要助力，也是教師從事教育的共同夥伴。因此實驗計畫行政單位應負責家長觀念之溝通，並鼓勵家長參與校務規畫，支援教學。

      h3 成長資源

      p 1.實驗計畫成立家長成長團體，從事親子教養觀念與實務討論。
      p 2.提供外界資訊，供家長參考。
      p 3.鼓勵自發性的家長聯誼活動，促進家庭交流。

      h3 親師互動

      p 1.製作班級通訊錄。
      p 2.期初、期中、期末均召開親師會議，討論親師協同事宜。
      p 3.定期舉辦親師生課程調查及討論，檢討教學成效，以為改進。

      h3 支援模式

      p 1.教師得視教學之需要，請家長協同課室教學。
      p 2.實驗計畫得聘請學有專長的家長擔任教師，以豐富課程內容，並協助第三學程學生完成學習規畫。
      p 3.針對學生個別特殊學習需求，教師得請家長協助執行該生之教學規畫。
      p 4.實驗計畫歡迎家長擔任義工，協助學生認輔、開放學習區管理、及其他教學服務事項。

      h2 十五、校園空間規畫

      p 開放學習區

      p 為提供學生非結構性之學習機會，協助學生了解自我性向，培養專精技能，開放學習區之設置，實屬必要。實驗計畫將逐步開設下列學習區，並由專科教師駐守：

      p 1.文史哲開放學習區
      p 2.外文開放學習區
      p 3.數學開放學習區
      p 4.自然科學開放學習區
      p 5.美術開放學習區
      p 6.音樂開放學習區
      p 7.韻律及戲劇教室
      p 8.電腦開放學習區 
      p 9.家政專科教室

      p 學生活動中心

      p 自主學習學生需要可以自由使用的活動中心，做為學生空堂課及社團會議、活動之用。

      p 綜合討論室

      p 實驗計畫預估每班需要二間討論室，統合運用，以因應分組教學需求。

      p 諮詢輔導室

      p 實驗計畫親師生均需經過一定階段的內在轉化歷程，不同學程學生有不同的輔導需求，實驗計畫應設專人負責學生心理輔導與生涯規畫諮詢，故有諮詢輔導室的設置必要。



      h2 十六、社會資源的引入與運用

      p 實驗計畫自成立初始，即結合學術單位、民間組織、社區資源、個別人力，進行規畫及教學。
      p 資源之引入係透過內部需求調查、外部資源尋求、洽談與協商，以求有效整合。並於學期結束後做期末評估，以決定是否繼續或調整。
      p 未來學術傾向明顯、學習速度較快的學生，實驗計畫希望利用大學先修制度，推薦學生就讀；就業意願明顯的學生，則希望能與社區及職業學校合作，推薦學生進行職業準備。

      p 實驗計畫校外學習及學生主題研究學習部份，亦透過家庭力量，引用社會資源，未來實驗計畫亦希望透過社團服務工作，回饋社區。

      p 社會資源引入之相關合作計畫，另陳教育局備查。
  
      p 社會本來就是一個大的學習場域，實驗計畫各科教學經常利用博物館、美術館、國家音樂廳、戲劇院、茶坊、各實驗劇場等進行教學或學習活動。這是實驗計畫彌補本身資源設備之不足，豐富學生學習經驗之必要措施。未來學生分化日益明顯，運用社會資源之需求相信亦將日益強烈，希望隨著教育觀念的開放，實驗計畫學生在利用社會資源方面，能夠得到更多的善意回應。






      h2 十七、實驗計畫財務結構及基本需求

      h3 人事需求

      p （一）教師人力：
      p 每班置專任教師兩名
      p 視學生各科學習速度與需求，採分級、分組上課制。除特教學生外，必修學程每組開課最低人數為十人；選修學程每組開課人數為五人，所需授課鐘點數超過專任教師鐘點數時，另聘兼課教師。兼課教師名額視需要而定。
      p 特教生依輔導需求，及補助專款開課。
      p 自學學程學生申請開課人數同選修人數。
      p 選修課開課人數不足時，得由學生以差額自付方式開課。

      h3 行政人力：
      p 主持人一名
      p 研究／行政助理一至兩名
      p 教務行政一名（得由教師兼任）
      p 輔導／特教行政一名（得由教師兼任）
      p 醫護、校警及工友人數視班級數及實際需要而定

      p 教學設備：按高級中學設備標準


      h3 財務結構及來源：

      p 自主學習實驗計畫為公民合作計畫，其精神在以政府比照小型學校原有教育投資，給予人事及經費運用的合理彈性空間，以尋求更具效能的財務運作方式。因此，自主學習實驗計畫預計以政府比照（一）學生單位成本（二）小型學校單位成本及（三）專案輔助，三大基準核撥。若有不足，則由參與家庭比例分攤。

      p 九十學年度第一學期所需經費以每年每生單位成本新台幣十二萬元編列，學生人數以九十人列計。 (即新台幣五百四十萬元整)。自九十學年度第二學期起，所需經費以每生單位成本新台幣十二萬元編列，依各年級班級人數核編，方式如下：
      p 每班人數二十五人以上，以實際人數列計。
      p 每班人數十～二十四人，均以二十四人列計。
      p 學生總數不得超過教育局核定實驗計畫學生人數三班九十人。


      p 〈附件一〉
      p 台北市自主學習實驗計畫  生活公約
      p （1998年9月1日生活會議通過實施）

      p 一、人際關係
      p 1.尊重他人權益，不妨礙他人自由，不打人、罵人或人身攻擊。
      p 2.以理性溝通解決爭執，不把過錯推到別人身上。
      p 3.如果有人讓你不舒服，可以口氣平靜、態度堅定的告訴他，請他停止。不要口出惡言，引發爭執。
      p 4.認真工作，誠實待人。不惡意騙人，也不利用、陷害他人；不唆使他人做違反道德、自治法規或其他違反法律的事。
      p 5.尊重他人的感覺，不說髒話；不挑釁；不偷、搶、恐嚇他人；也不擺出瞧不起人的樣子。
      p 6.學校不可帶香煙、西瓜刀、蝴蝶刀、雙節棍、打火機、毒品、酒、槍（包括玩具槍）、Gameboy、遊戲軟體、遊戲光碟、色情書刊、B.B.Call等物品到校。

      p 二、生活會議
      p 1.尊重主席，不干擾會議進行，也不干擾他人發言。
      p 2.發言時附理由，對事不對人，以進入有意義的討論。
      p 3.尊重個人自由意志，不干擾他人決定。
      p 4.對自治法規不服時，需找五人連署，在生活會議中提案修改。但修改通過前或翻案不通過時，仍需遵守該條文。
      p 5.得經生活會議之討論，將法庭出現的新案例，列入生活公約新增條文。

      p 三、法庭
      p 1.法庭法官經由生活會議之信任投票產生。負責師生行為之評斷與爭議之處理。
      p 2.法庭進行中，應尊重法官，不得干擾程序之進行。
      p 3.除非請假，原告、被告及證人必需出庭。
      p 4.當事人要相互尊重，不得誣告、偽證或口出惡言、做人身攻擊。
      p 5.法官團覺得必要時，可請參與出庭的人離開。
      p 6.被告同意判決結果，需在訴狀上簽名。不服判決者，可以提出上訴，由不同法官再次審理。同一案件只能提出一次上訴。被告對上訴判決結果，必須接受。
      p 四、上課時
      p 1.上課要尊重教師的授課方式，有問題要舉手發言，不可放出寵物，並按時繳交作業。
      p 2.不遵守課堂秩序，經教師提醒仍不改善者，教師得將他請出課堂，以免影響他人好好上課的權益。
      p 3.出外時，要注意禮儀，並在教師面前集合，聽取注意事項後，才可以出發。結束時，也要在教師面前集合，等教師宣佈後，才能解散。
      p 4.破壞教具或公物，須照價賠償。尤其不可將物品丟到魚池中。
      p 5.進入專科教室要遵守使用規則。除非通過認證，否則不得擅自使用設備和器材。

      p 五、圖書館
      p 1.圖書館為安靜區，不可吵鬧，也不可以影響別人。
      p 2.書看完後，請立刻放回原處。
      p 3.按規定辨完借書手續才可以把書帶離圖書館。
      p 圖書館內除白開水外，不可以吃喝任何東西。

      p 六、新增條款
      p 1.不在走廊、教室玩球、射飛機、揮棍棒、潑水等危險行為。（1998.9.28）
      p 2.手機得自行保管，但校園內不可開機使用。（2000.9 .1）

      .ui.divider

      .ui.buttons
        router-link.ui.huge.green.button(to="/book") 
          sui-icon(name="book")
          | 回走書與文集
        router-link.ui.huge.teal.button(to="/") 
          sui-icon(name="home")
          | 回首頁
</template>

<script>

export default {
  name: 'BookOne',
  metaInfo: {
    title: '自主學習實驗計畫',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
