<template lang="pug">
  .hello
    .ui.container.segment
      img.floated.left(src="../assets/隨緣自強.jpg")

      br
      br
      .ui.header 諮詢

      p 我的email:
        a.underline(herf="mailto:water5127@gmai..com")  water5127@gmail.com

      p 收費方式：待討論

      .ui.buttons
        router-link.ui.huge.green.button(to="/book") 
          sui-icon(name="book")
          | 走書
        router-link.ui.huge.teal.button(to="/") 
          sui-icon(name="home")
          | 回首頁

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '瞭解',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
