<template lang="pug">
  .hello
    .ui.container.segment
      .ui.row
        img.floated.left#a(src="../assets/water02.jpg")
        .ui.header 回首生命歷程
        p 因果之間總有牽連。

        p 一個人之所以變成今日的模樣，並具有自己堅守的價值信念，形成獨特的行事風格，必與他過去的經驗相關。

        p 我覺得我之成為現在的我，是因為以下的重要因緣:
      .ui.row
        ol
          li
            router-link.underline(to="/story1") （一）道家惠我
          li
            router-link.underline(to="/story2") （二）學育潤我
          li
            router-link.underline(to="/story3") （三）個人年表和記事
 
      .thin-only.ui.vertical.buttons
        router-link.ui.huge.green.button(to="/story1") （一）道家惠我
        router-link.ui.huge.blue.button(to="/story2") （二）學育潤我
        router-link.ui.huge.orange.button(to="/story3") （三）個人年表和記事
        router-link.ui.huge.teal.button(to="/") 
          sui-icon(name="home")
          | 回首頁
        router-link.ui.huge.purple.button(to="/talk")
          sui-icon(name="comments")
          | 交流

      .fat-only.ui.buttons
        router-link.ui.huge.green.button(to="/story1") （一）道家惠我
        router-link.ui.huge.blue.button(to="/story2") （二）學育潤我
        router-link.ui.huge.orange.button(to="/story3") （三）個人年表和記事
        router-link.ui.huge.teal.button(to="/") 
          sui-icon(name="home")
          | 回首頁
        router-link.ui.huge.purple.button(to="/talk")
          sui-icon(name="comments")
          | 交流

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '瞭解',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  ol {
    position: relative;
    left: 1em;
    font-size: 18px;
    display: inline-block;
  }

  li {
    line-height: 1.8;
  }

  #a {
    margin-top: 2em;
  }

</style>
