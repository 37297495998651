import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from '@/components/HelloWorld.vue';
import HelloWorld2 from '@/components/HelloWorld2.vue';
import About from '@/components/About.vue';
import Story from '@/components/Story.vue';
import Story1 from '@/components/Story1.vue';
import Story2 from '@/components/Story2.vue';
import Story3 from '@/components/Story3.vue';
import Talk from '@/components/Talk.vue';
import Epaper from '@/components/Epaper.vue';
import Help from '@/components/Help.vue';
import Book from '@/components/Book.vue';
import Book1 from '@/components/Book1.vue';
import Slide1 from '@/components/Slide1.vue';
import Admin from '@/components/Admin.vue';

Vue.use(Router);
export default new Router({
  routes: [
    {
      path: '/',
      name: 'HelloWorld',
      component: HelloWorld
    },
    {
      path: '/h2',
      name: 'HelloWorld2',
      component: HelloWorld2
    },
    {
      path: '/admin',
      name: 'AdMin',
      component: Admin
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/story',
      name: 'Story',
      component: Story
    },
    {
      path: '/story1',
      name: 'Story1',
      component: Story1
    },
    {
      path: '/story2',
      name: 'Story2',
      component: Story2
    },
    {
      path: '/story3',
      name: 'Story3',
      component: Story3
    },
    {
      path: '/talk',
      name: 'Talk',
      component: Talk
    },
    {
      path: '/epaper',
      name: 'Epaper',
      component: Epaper
    },
    {
      path: '/help',
      name: 'Help',
      component: Help
    },
    {
      path: '/book',
      name: 'Book',
      component: Book
    },
    {
      path: '/book1',
      name: 'Book1',
      component: Book1
    },
    {
      path: '/slide1',
      name: 'Slide1',
      component: Slide1
    }
  ],
});
