<template lang="pug">
  .hello
    .ui.container.segment.grid
      .ui.row
        .ui.four.wide.column
          img.small(src="../assets/water03.jpg")

        .ui.ten.wide.column
          .ui.header 感謝你進到我的網站，願意花點時間交流。

          br
          
          p 為了避免社交平台的喧嘩，請利用我的信箱，並以文字方式進行。
            a.underline(href="mailto:water5127@gmail.com") water5127@gmail.com

          p 我會認真閱讀、回應，也請您同意未來我將交流內容，去掉一切個人資訊後，重新整理成文章發表。

          p 在多元價值的時代，每個人都有他的獨特性。我們無法妄求人人的價值觀相同，但是只要願意「附理由」的公開自己所思所想，就是對世界的善意了。

          p 讓我們從此出發，彼此協同。謝謝！

          br

          .ui.buttons.centered
            router-link.ui.huge.green.button(to="/about")
              sui-icon(name="home")
              | 回首頁
            router-link.ui.huge.orange.button(to="/book")
              sui-icon(name="book")
              | 走書與文集

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '交流',
  },
  data: () => ({})
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #right {
    margin-left: 3em;
  }

  img.small {
    width: 160px !important;
    max-width: 50%;
    border-radius: 5px;
  }

  img.ui.avatar {
    width: 50px;
    border-radius: 25px;
  }

  p {
    margin-left: 2em;
  }

</style>
