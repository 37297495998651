<template lang='pug'>
  #app
    sui-menu#nav-bar(:widths="6" inverted="")
      router-link.item(to="/")
        sui-icon(name="home")
        span.fat-only 首頁
      router-link.item(to="/epaper")
        sui-icon(name="edit")
        span.fat-only 近思新想
      router-link.item(to="/about")
        sui-icon(name="calendar")
        span.fat-only 緣起
      router-link.item(to="/story")
        sui-icon(name="user")
        span.fat-only 故事
      router-link.item(to="/talk")
        sui-icon(name="comments")
        span.fat-only 交流
      //router-link.item(to="/help")
        sui-icon(name="help")
        span.fat-only 諮詢
      router-link.item(to="/book")
        sui-icon(name="book")
        span.fat-only 走書與文集
    router-view
    ad.fat-only
</template>

<script>

import Ad from './components/Ad-Be.vue'

export default {
  name: 'App',
  components: { Ad },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: '歡迎',
    // all titles will be injected into this template
    titleTemplate: '%s | 李雅卿的隨緣自強',
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    track(t, v) {
      this.$gtag.event('action', {
        event_category: t,
        event_action: t,
        event_label: t,
        value: v,
      });
    }
  },
};
</script>

<style>

  .ui.header {
    font-size: 26px !important;
  }

  p {
    font-size: 20px;
    line-height: 1.6;
    margin: 1em;
  }

  .router-link-exact-active {
    background-color: #092 !important;
  }

  img.floated.left {
    float: left;
    margin-right: .5em;
    width: 400px;
    max-width: 85%;
    height: auto;
  }

  img.big {
    margin: 0 10%;
    min-width: 0;
    width: 80%;
    height: auto;
  }

  img.main {
    margin-right: .5em;
    width: 400px;
    max-width: 85% !important;
    height: auto;
  }

  img.small {
    margin-right: .5em;
    width: 400px;
    max-width: 95% !important;
    height: auto;
  }

  @media screen and (min-width: 601px) {
    .thin-only {
      display: none !important;
    }
  }

  @media screen and (max-width: 600px) {

    .fat-only {
      display: none !important;
    }

    #nav-bar {
      width: 100vw !important;
    }

    .item {
      font-size: 12px !important;
    }

    img.floated.left {
      float: none;
    }
    .ui.header {
      font-size: 20px !important;
    }
    p {
      font-size: 16px;
    }
    .fat-only {
      display: none;
    }
  }

  a.d {
    color: #00c;
  }

  a {
    color: black;
  }

  a.underline {
    text-decoration: underline;
  }

  a:hover {
    color: black;
  }

  #main {
    float: left;
    display: block;
    width: 450px;
    height: 400px;
    background-image: url(https://docs.google.com/drawings/d/e/2PACX-1vTR4UidwWFfr4BigZososFrArDHkLthBtYriIwCPw0HZAC67kbxexCiCAqNxtKrVbOGIZ5SB3DV5nec/pub?w=960&amp;h=720);
    background-size: 450px 400px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .bookmark {
    font-family: "標楷體", "儷黑 Pro";
  }

</style>
