<template lang="pug">
  .hello
    .ui.container.segment
      img.floated.left(src="../assets/water02.jpg")

      .ui.header （一）道家惠我

      p 我是個多災多病的難養小孩，在我12歲前，
        a.underline(href="#bookmark1") 媽媽總擔心我會被神仙接走
        | 。直到結婚生子後，因緣巧合地接受道家修真之術，在煉氣、學拳和丹針之術的協助下，終於讓自己脫胎換骨，而有今日的體健安康。

      p 很多人說我看起來不像七十歲的人，因為體態輕盈，行動敏捷。人們卻不知道，我沒有一天不練身，沒有一天不修行。我知道自己先天嚴重不足，所以要靠後天的努力來補足。

      p 辦學前我正式拜過兩位師父，一位是
        a.underline(href="#bookmark2") 黃龍丹院
        | 的來靜師父，另一位是太極拳
        a.underline(href="#bookmark3") 熊衛師父
        | ，還和
        a.underline(href="#bookmark4") 蘇永安老師
        | 學過針炙，了解「上醫者治未病」的道理。這些傳統的養生智慧，不但延續了我的生命和健康，也影響了我待人處世的基本原則。

      p 現在我知道，人要長保安康，其實不難。只要好好的吃，好好的睡，加上好好運動，起居行止回歸自然，順天應時，無論先天條件如何不足，最後都可以無病無災。

      p 好好的吃，指的是粗食勝細糧，多元勝單色，輕調理，淡味欲。食毋過飽，夜毋再食。因為自然賦予的各種食物都有它的特點，探索每種食材的特性，品嚐它們的滋味，實在是人生樂事。我不知道為什麼許多人視廚房為畏途？也不了解外食文化怎麼會變成台灣的流行文化？如果只煮自己一個人的餐食，其實比叫外賣快多了。不但省時省錢，而且衛生、好吃、營養均衡。以前我辦的各級自主學習學校，食衣住行相關的生活課程總排在必修課第一位，因為它們才是維持生命存活最基本的能力。

      p 好好的睡，指的是大家身熟能詳的「晚上十時前入眠」之外，不做虧心事、不出傷人言，大度待人、寬心處事，不糾結、不纏繞，看開、放下，用愛心說真話，自能平和處世，心安理得。不至於在睡夢中百轉千迴，甚至輾轉難眠。

      p 好好運動嘛，我是靠太極拳脫胎換骨、轉危為安的。然而不是每個人都有這樣的機緣，也不是每個人都耐得住太極拳中專氣致柔、纏絲運旋的鍛練。我覺得只要找到適合自己的運動，體會運動當時身心調和之美，並且持之以恆地做它，那怕只是每天大步走路，也都很好的。

      p 這些養生的道理就是自然之道，甚易知、甚易行，然而大家都說難！難！難！想來人一天只有24個小時，每個人看重的不一樣。怎麼規畫並使用這24小時，端看此人看重的是什麼吧。

      p 我很少和人討論「氣、拳、醫」相關的議題，一來因為現代人太忙了，老是求成速效的想要立求靈丹妙藥，不肯回歸本質調理。二是因為我對它們從切身體悟中得知自己所得不過九牛之一毛，雖足以自救持家，但是「器愈利則愈易傷」。

      p 我曾從不同修習者身上，看到他們因修習不得法，而致發生練氣出偏，練拳傷膝、妄醫傷人等等遺憾的事。至於對此嗤之以鼻，不屑聞問的人也不在少數。

      p 得益於師父的指點和自己不妄求的心，我很幸運的避開了各種修習危險。所以當銜師命交接後進者時，我總是先言明
        a.underline(href="#bookmark5") 相關的危險和避險之道。
        | 以免自己淪為那些搏名歛財，誇大氣功拳效神醫的人。

      p 但在師父眼裡，我也是個劃地自限的弟子吧？因為我一學到足以自用自救之後，就抱此而止。後來更將心力轉於辦學之途，放棄精修之路，終致無法成材，枉費師門濟世之託，甚感慚愧。

      p 雖與師門漸行漸遠，但我還是認為自己是道門中人，連辦學時提出的的「自主學習」四字，也是從道家精神轉化而來。

      p 我覺得老子道德經短短五千言，已說盡天地人事。後代數千年不同修行者的衍伸與再發現，亦有可觀。我只約略觸及氣、拳、醫三者，並知此皆實證之學，唯有親身修習、不斷探究者才能體悟其中奧妙。至於「參天化地之德」，以道治天下等，則完全未能觸及。

      p 然此生惠我者，實乃道也。

      br

      .ui.divider

      br
      br

      a.bookmark(name="bookmark1")
        p 註一：@我的母親來自江蘇宿遷的落馬湖，據說在大陸淪陷前是當地首富，家裡曾經出過狀元，院落立有「百官到此下馬」的旗竿。親族中也出過神仙羅漢，老宅裡還住了個狐仙。有幾個孩子在12歲前夭折，其母都會夢到孩子回來告知，是被某某神仙收去當童子了。所以我媽老以為我是會被神仙收走的孩童之列。沒想到我不但撐過了12歲大關，還因緣巧合地成為她三個孩子中最健康的一個，現在96歲的她也是由我來相伴侍終，這確實讓媽媽覺得意外。

        p 記得我讀小學時是有體罰的，不但要勞動服務，每天升旗時還要全校列隊聽師長訓話。中部的太陽很烈，每當師長訓話太長，或割草太久，或全班被罰舉椅的時候，大家想的不是自己行為的是非過錯，而是李雅卿什麼時候才會暈倒來解救大家？身為班長或是國歌指揮的我，站在排頭，烈日下一站久，眼前光影就會從白轉黃，從黃轉橙，再從橙轉黑的當眾暈倒。於是校長就會急急宣佈解散，同學一起抬起我衝向醫務室，全部的人就都得救了。這是我最鮮活的小學記憶。

        p 中學到研究所，每個月我總要請上幾天病假，無法出門。我記得媽媽流著淚替我燙活泥鰍作湯的場景，也記得各種滋味奇怪的藥。宿舍的室友也很習慣我抱個湯婆子捂著肚子，冷汗直流的慘狀。至於自己摸著馬桶邊冰涼的磁磚悠悠醒來的虛幻感，對我更不是什麼新鮮事。

        p 婚後我家浴室從不內鎖，因為不知何時我會暈倒在裡面。外子常笑我靠著一口氣撐著。直到我懷了老大，把嚴重的子宮後曲頂正，生了老二開始接觸道家養生之術，我才逐漸克服自己先天性的各種過敏體質、嚴重貧血等等毛病，而且敢承諾小孩，一定會陪著他們好好活著。

      br
      .ui.divider
      br

      a.bookmark(name="bookmark2")
        p 註二：@我排龍門宗丹鼎派第十四代，為復字輩弟子。
          a.underline(href="#bookmark6") 來靜師父
          | 在度過一次生死交關的疾病之後，決定廣開師門，故黃龍丹院弟子極多，其中難免不齊。然而有些師兄終生以科學方式揭開氣功的神密面紗為職志，也有很多師兄弟姊妹一生安靜自持，自度度人，讓我非常敬佩。

      br
      .ui.divider
      br
      a.bookmark(name="bookmark3")
        p 註三：@我有幸親炙熊衛師父，得他手把手地教過太極拳幾套相關拳術，並為師父整理他對拳經的體悟，出版過
          a.underline(href="#bookmark7") 太極導引
          | 一書。我在師父說明演示
          a.underline(href="#bookmark8") 太極拳經
          | 所云各項拳理境界時，了解氣拳合一、以慢制快、以弱勝強之理，並因此脫胎換骨，強身健體。諸家太極拳術最後我只留下一套「陳家小架」，而且終生未曾停止修習。

      br
      .ui.divider
      br
      a.bookmark(name="bookmark4")
        p 註四：@蘇永安老師的施針術是極好的，其它我未曾接觸。

      br
      .ui.divider
      br
      a.bookmark(name="bookmark5")
        p 註五：@我曾在初習靜坐時誤接宇宙能量，並在毫無心理準備的情況下，被它的深邃壯闊嚇到幾乎癱瘓。來靜師父聞知此事，問我有何打算？我想了幾天，決定不求神通，但願安康。自此改練動功，專心打拳。

      br
      .ui.divider
      br
      a.bookmark(name="bookmar6")
        p 註六：@據說我的師爺東渡來台，只收徒二人，李師伯傳承了醫道，來靜師父傳承了功法。我很喜歡師伯，常去他的古道堂醫館行走。見他收治了好多練氣出偏的病人，並對坊間輕易傳授氣功，誇大神通的現象，很是憂心。

      br
      .ui.divider
      br
      a.bookmark(name="bookmark7")
        p 註七：@熊衛師父為求因應現代人的忙碌，彙整各家太極拳的精髓，整理出了「太極導引十二式」，而且訓練了一些教練作推廣，我也是其中之一。辦學期間我曾在主婦聯盟或學校的家長團體中，帶過團修，陪伴家長們健身。團修之前，我除了交代師門傳承之外，更著重練拳避傷之道。並強調下列原則是絕對不能忘的:

        p 1.要認真，不要努力~~「認真」是把注意力放在每個動作中，時時審視自己是否專注鬆柔？不滯不滑？「努力」則是勉強自己，求成速效。前者能讓自己進步，後者必使自己受傷。

        p 2.可切磋，不可比較~~彼此切磋是指討論、互練時不可藏私，才能共同進步。但每個人的身體狀況不同，如果妄加比較，難免起豔羨心，而致勉強自己或他人，導致受傷。所以練拳養生是「個人的事」，即使團練，也是各練各的，無可比較。

        p 3.自勝者強~~練拳本來就是吃苦流汗的事。尤其一開始，除了流汗、酸楚、極限下的忍耐外，完全沒有什麼身心合一、氣脈周轉的美妙可言。熊先生總是說太極拳就是自己往自己的內在打，往自己的深處打，覺得自己撐不下去時，就勉強自己再多站一秒鐘，再多旋一個圈，然後才休息。如此，必能讓今日的我比昨日的我進步一點。久了，自然心有所得，體有所成。學拳練氣最怕的是三天打漁兩天晒網。這個道理後來我用在創事業、辦學校、過生活上，也以此讓自己走過一次次困苦和艱難，而少有自我放棄的遺憾。

        p 4.鬆而不散，沈而不滯、綿而不絕~~~腰為主宰，九關同旋（腰、脊、頸;肩、肘、腕 ; 胯、膝、踝）。至於呼吸，一開始，自然就好。

      br
      .ui.divider
      br
      a.bookmark(name="bookmar8")
        p 註八：@我覺得儒、釋、道三者已融入中華文化，讀書人很難不有所涉獵，並受其影響。但縱觀中國歷史，治國處事者能因禍而為福，轉敗而為功者，必為道門中人。至於能「執其環中以應無窮」者，就更不必說了。至於外國政治家或企業經營者，成功之道也都能相互映照，想來是古今中外人性總是相通的吧。

      br
      br

      .ui.buttons
        router-link.ui.huge.green.button(to="/story") 故事
        router-link.ui.huge.blue.button(to="/story2") （二）學育潤我

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '瞭解',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
