import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database' 
import { getAuth } from 'firebase/auth'

// Initialize Firebase
const fb = initializeApp({
  apiKey: 'AIzaSyC_-NMMEZa9tpHEOiy2EWfpBMcVw0K7_8k',
  authDomain: 'life3-a7619.firebaseapp.com',
  databaseURL: 'https://life3-a7619-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'life3-a7619',
  storageBucket: 'life3-a7619.appspot.com',
  messagingSenderId: '891270461612',
  appId: '1:891270461612:web:5263fe272db482835ec709',
  measurementId: 'G-MM82LS4WRE'
})

export const db = getDatabase(fb)
export const auth = getAuth(fb)
