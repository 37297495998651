<template lang="pug">
  .hello
    .ui.container.segment
      iframe(src="https://docs.google.com/presentation/d/e/2PACX-1vRApdi79R5jm7VODtHw5DZVEFT8ta-Zk0P1tABz3o1eAoK_un93U5LMTfn1MqRq2hUEFp_6_UidwcR5/embed?start=true&loop=true&delayms=1000", frameborder="0", width="100%", height="569", allowfullscreen="true", mozallowfullscreen="true", webkitallowfullscreen="true")

      .ui.divider

      .ui.buttons
        router-link.ui.huge.green.button(to="/book") 
          sui-icon(name="book")
          | 回走書與文集
        router-link.ui.huge.teal.button(to="/") 
          sui-icon(name="home")
          | 回首頁
</template>

<script>

export default {
  name: 'BookOne',
  metaInfo: {
    title: '自主學習實驗計畫',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
