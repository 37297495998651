<template lang="pug">
  .hello
    .ui.container.segment
      .ui.grid
        .ui.row
          .ui.four.wide.column
            img.small(src="../assets/隨緣自強 的副本.jpg")
          .ui.ten.wide.column
            .ui.header 走書

            .ui.list
              a.item(href="https://books.google.com.tw/books/about/%E8%87%AA%E4%B8%BB%E5%AD%B8%E7%BF%92%E7%90%86%E5%BF%B5%E5%85%AD%E8%AC%9B.html?id=8REIMwAACAAJ&redir_esc=y", target="_blank") 
                i.book.icon
                | 自主學習理念六講
              a.item(href="https://sites.google.com/view/autoalearn/%E5%87%BA%E7%89%88%E5%93%81%E6%8E%A8%E5%BB%A3/%E8%87%AA%E4%B8%BB%E5%AD%B8%E7%BF%92%E6%89%8B%E5%86%8A", target="_blank")
                i.book.icon
                | 自主學習手冊
              a.item(href="https://sites.google.com/view/autoalearn/%E5%87%BA%E7%89%88%E5%93%81%E6%8E%A8%E5%BB%A3/%E7%95%B0%E5%8B%95%E4%B8%AD%E7%9A%84%E6%B0%B8%E6%81%86", target="_blank")
                i.book.icon
                | 異動中的永恆
              a.item(href="https://sites.google.com/view/autoalearn/%E5%87%BA%E7%89%88%E5%93%81%E6%8E%A8%E5%BB%A3/%E6%A0%A1%E5%9C%92%E9%9D%92%E5%B0%91%E5%B9%B4%E4%BA%BA%E6%AC%8A%E6%89%8B%E5%86%8A", target="_blank")
                i.book.icon
                | 校園青少年人權手冊
              a.item(href="https://www.books.com.tw/products/E050013392", target="_blank")
                i.book.icon
                | 種籽手記
              a.item(href="https://www.books.com.tw/products/E050013541", target="_blank")
                i.book.icon
                | 乖孩子的傷最重
              a.item(href="https://www.taaze.tw/products/11100321015.html", target="_blank")
                i.book.icon
                | 北政實驗手記
              a.item(href="https://www.books.com.tw/products/0010335877", target="_blank")
                i.book.icon
                | 另類教育在台灣
              a.item(href="https://www.books.com.tw/products/0010687205", target="_blank")
                i.book.icon
                | 跟孩子一起玩數學
              a.item(href="https://www.books.com.tw/products/0010759330",target="_blank")
                i.book.icon
                | 普格碼島的法師


            .ui.header 文集

            .ui.ordered.list
              router-link.item(to="/book1")
                i.book.icon
                | 自主學習實驗計畫(書)
              router-link.item(to="/slide1")
                i.book.icon
                | 自主學習的理念與實踐(簡報)

        .ui.row
          .ui.vertical.buttons.thin-only
            router-link.ui.huge.green.button(to="/") 
              sui-icon(name="home")
              | 回首頁
            router-link.ui.huge.orange.button(to="/epaper")
              sui-icon(name="edit")
              | 近思新想
          .ui.buttons.fat-only
            router-link.ui.huge.green.button(to="/") 
              sui-icon(name="home")
              | 回首頁
            router-link.ui.huge.orange.button(to="/epaper")
              sui-icon(name="edit")
              | 近思新想

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '瞭解',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .item {
    font-size: 18px;
  }

</style>
