<template lang="pug">
  .hello
    .ui.container
      router-link(to = "/")
        img.floated.left(src="../assets/隨緣自強.jpg")

      a(name="bookmark1")
        p 註：@「隨緣自強」是我在政大求學時，呂春沂老師說的。呂老師是抗戰時期西南聯大的高材生，極具文采風骨。很早就看出中共那一套不可信，隨政府來台後，卻因言賈禍，但心胸豁達。雖然妻女為此避居美國，他一人住在教師宿舍，只能永遠領著講師薪水，沒有升等之望，但他卻無一怨言。呂老師對學生極好，我們幾個研究生常在他家吃「糊塗鍋」、唱京劇、吟詩作對、並且亂聊天。我從他身上約略體會到當年西南聯大的校訓:「自由、自然、自在 ; 如雲、如海、如山」。現在想想，他的言行和當年政大法學院中幾位老自由派的教授，對我們這些終生持守民主自由的學生，確實影響很大。

        p 另外和「隨緣自強」一樣，放在我心裡的一句話是「我命由我不由天」，這句話是當年我拜入道家丹鼎派黃龍丹院時，來靜師父給我的訓勉。取自紫陽真人張伯端的「悟真篇」。而東晉葛洪祖師「抱朴子」說的是「我命在我不在天，還丹成金億萬年」。只是這幾個字後來從道家的修真延壽衍伸為自立自強的勸世語錄，據說有人甚至續了下半句「天若滅我，我滅天」，並以此霸氣自得，真是完完全全錯失了原義。

        p 說起「命由誰？」這事，我還有個切身經驗。那是我中學時期，和同學到一位據說很靈的先生處學算命，老師第一堂課先說了一些通論，之後拆詞解字，談及「命運」二字時，他說「命，可運也」，接著說起各種因緣改運之道。我聽著聽著忍不住問老師:「既然命可運，而改運的方式基本不外做好事，濟窮救命什麼的，那麼一個人平日裡就心懷慈悲，能捨能給，不欺暗室的話，這人也就自然會好命了，是嗎？」我忘了當時老師是怎麼回應我的。只知道自己從此不卜卦，不算命，也就是打算自己的命運自己創啦！

      br
      .ui.vertical.buttons.centered.thin-only
        router-link.ui.huge.green.button(to="/about")
          sui-icon(name="calendar")
          | 瞭解更多
        router-link.ui.huge.orange.button(to="/epaper")
          sui-icon(name="edit")
          | 近思新想
      .ui.buttons.centered.fat-only
        router-link.ui.huge.green.button(to="/about")
          sui-icon(name="calendar")
          | 瞭解更多
        router-link.ui.huge.orange.button(to="/epaper")
          sui-icon(name="edit")
          | 近思新想

</template>

<script>

export default {
  name: 'HelloWorld',
  metaInfo: {
    title: '歡迎',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main {
  padding: 0;
  max-width: 50vw;
}

.ui.list {
  display: block;
  text-align: left;
  margin-left: 33vw !important;
}

.timeline {
  max-width: 720px !important;
  color: white !important;
  margin: 0 auto;
}

@media screen and (max-width: 420px) {
  .timeline {
    position: relative;
    left: -2em;
  }
  .ui.list {
    margin-left: 15vw !important;
  }
  .sunflower p {
    margin-left: 2vw !important;
  }
}

.be {
  height: 4em;
  border-radius: 30px;
}

.white {
  color: white;
}

.sunflower p {
  margin-left: 15vw;
}

.column .ui.list {
  height: 8em;
  min-height: 0;
}

.column.down {
  padding-top: 2em;
}

.mini {
  height: .8em;
  margin: 0 .2em;
}

</style>
