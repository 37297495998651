import Vue from 'vue';
import './registerServiceWorker';
import SuiVue from 'semantic-ui-vue';
import VueSimpleMarkdown from 'vue-simple-markdown';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import router from './router';
import 'semantic-ui-css/semantic.min.css';
import App from './App.vue';
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: { id: 'G-HKDY41X625' },
}, router)

Vue.use(VueSimpleMarkdown)

Vue.use(SuiVue)
Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
