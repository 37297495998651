<template lang="pug">
  .hello
    .ui.container.segment
      img.floated.left(src="../assets/water02.jpg")

      .ui.header （三）個人年表和記事:

      p 1954年生於南投縣魚池鄉，隨父母遷居埔里、草屯，婚後住在台北，退休後一度隱於烏來，但為兼顧父母和公婆，遷移淡水。

      p 身為大陸移民的第二代，完全沒有田產祖蔭可言，一切都靠父母赤手空拳地努力掙得全家的溫飽。到現在，我對「家鄉」的概念都很淡薄，總覺得「媽媽在哪裡，家就在那裡。」

      p 東渡來台的外省人，很知道自己是個外來者，本地人是否接納你？完全要看自己的行事作風。我從父母的身教中知道，只要不涉嚴重的利益衝突，你用什麼態度待人，別人就會用同樣的態度對你。因此敦親睦鄰是我最先學到的處事態度。記得媽媽只要蒸了饅頭、包子，就會要我們用拖盤捧著和鄰居們分享。而鄰居的炸粿、油飯也常常出現在我們的餐桌上。「不可讓人空盤而回」是鄰舍間最素樸的來往原則。後來我在書本中讀到「仁」的意義，知道「視人如己」是作人最基本的原則。孔子說「君子無終食之間違仁，造次必於是，顛沛必於是」。但是無數的小說和自己切身經歷，也讓我了解利害關係之下，人性的扭曲。因此更加持守道家「不試探人性」的慈悲和智慧。

      .ui.divider

      p 6-24歲是學生歲月（埔里育英國小、埔里初中轉草屯國中、台中女中、政治大學法律系、政治大學法律研究所碩士）

      p 我整個學生歲月可以用「外強中乾」四個字來形容。外強者，我的功課好，人緣佳，一直是校園的風雲人物。中乾者，體質弱，貧血、過敏、常常昏倒，大學時更從樓梯上暈摔下樓，嚴重的腦震盪幾至喪命。但是此次的瀕死經驗，讓我知道死後世界其實一片光明，無需恐懼。因此，活著的時候要努力堔索這個美妙的世界，用自己的方式和可能來守護它，死的時候要如花葉離枝，自然飄落，回歸寰宇。

      p 我覺得自己是個活在當下的人，很少和過去的世界維持聯繫，也很少為不可知的未來作打算，倒是願意好好的過著每一天，並對可見的事物做規畫。因此快速融入環境是我的專長之一，不留戀人事物則是我的特質。這樣的人其實挺適合上山清修，只是我的塵緣太重，到今天都還在人間打轉兒。

      .ui.divider

      p 1978年結婚。

      p 我是真真正正自由戀愛、自己作主，在學生時代（研究所尚未畢業）就在校園辦了婚禮。婚後完成論文就和先生一起到報社工作。長子出生後接了公婆一起居住，同時扛起夫家四位弟妹的供養責任，直到他們成家立業。大家庭的婚姻生活，讓我深切體會傳統文化對長子（長媳）的要求，以及轉型社會中，婚姻給女子帶來的挑戰與機會。我曾深切反省其中的利弊，在中間做了各種艱難的取捨。同時期許自己，所有傳統文化綑綁我的部份，都要讓它到我而止，絕不轉移到下一代。而現代社會的開放可能，也要從我而起 : 我一定要讓自己和我的子孫自由。

      p 這個深沈的文化檢視與鬥爭過程，確實走得跌跌撞撞，我常在深夜一次又一次的自問反省，好幾次婚姻瀕臨決裂險境，幸而都在最後關頭與先生找到兼顧情理的轉寰可能。這不能不歸功於法政教育和哲學訓練給我們的啟發。當然彼此不忘結婚初衷的愛與承諾，也使我們願意認真並持續地經營這段生命中最重要的關係選擇。

      .ui.divider

      p 1978~~1990年新聞工作者（曾任
        a.underline(href="#bookmark10") 中國時報出售前
        | 的文教、司法、立法、黨政等路線及機動、專題調查記者、撰述委員、採訪組副主任、商業周刊副總編輯），參與台灣政治解嚴過程，並在了解台灣土壤嚴重毒化後，致力於生態與環境保護議題，參與籌設「新環境雜誌社」，催生了「主婦聯盟」，並支持後續「綠主張生活合作社」的出現。
       
      p 這十五年的新聞工作，讓我從報禁、黨禁的時代走到政治解嚴、國會改選、到政權的和平轉移。台灣這片土地也慢慢從可怕的環境惡化轉向永續經營，當髒臭的淡水河回到天光雲影映照的美麗水流時，我心中確實有共耕福田的安慰和驕傲。看到聯合國十七項永續發展目標成為台灣施政的指導原則，更讓我對未來充滿信心。

      p 在報禁時代，自媒體還沒有影子，中國時報和聯合報兩者激烈競爭。報社的主力記者真是有筆如刀，檯面上下都有巨大的影響力。也因此人人都想左右我們的觀點，企圖通過影響記者，達到他們的目的。

      p 像我這樣一手採訪新聞，二來製作專題，同時撰寫評論的新聞工作者很容易就會被稱為「女俠」，甚至「全民之師」。這些虛幻的詞冠卻讓我在午夜夢迴中，常常自責於自己知識之不足、查證不夠周全、態度過於急切等等失誤，而有如履薄冰之感。

      p 我主導過幾個轟動當時的大專案，如突破全國封鎖的索忍尼新採訪戰、周令飛採訪戰等等，幾乎成為新聞界傳奇。但是我記憶中更深刻的場景反而是幾次面對長官或老板的爭議:「我不能明明知道對方在說謊，而我還必須寫下來，公開報導。」、「這報紙不是我的，但這條新聞是我的。您可以不登這篇報導，但不能要求我更改它的內容和立場。」等等。

      p 我有幸在中國時報賣掉之前那段最輝煌、最開明的時期，和自由派的長官們共事，並得到老板的信任，而擁有極大的揮灑空間。他們總為我遮擋來自黨政高層的壓力，讓我能無所掛礙的衝刺。

      p 記得有一次立法院黨鞭邀請我上面三級的每位長官和我去吃「鴻門宴」，抱怨我在立法院煽動倒閣。席間他問說:「這位李記者，你到底是不是國民黨黨員啊？」我說:「是啊！我還上過革命實踐研究院呢！」他說:「你知不知道今天你在立法院的言行會有倒閣的危險啊？」我說:「知道啊！這樣的閣揆對國家不好啊。可惜沒倒成。」老先生搖搖頭問我的長官:「能不能讓這位小姐換個位置啊？」我的長官笑瞇瞇地舉杯敬他:「沒辦法耶，她是老板娘派的，我沒法子動她。」

      p 我擔任政治記者時鬧的笑話很多，但是幾個國家公園籌設前的探勘專題採訪，以及全國古蹟調查採訪，才是我最美好的新聞記憶。這些專題調查採訪，一來是我主動討來的差使，二來為報社打開了自然和人文保育的先河，三來我自己得以跟隨第一流的自然、動植物、歷史和人文學者，把台灣徹頭徹尾地跑了好幾圈，並深深愛上這個美麗的島嶼和它的歷史。這也是後來我常獨自開車帶著兩個孩子環島。辦學後，種籽小學和自主中學學生每學期都有五天四夜「大旅行」的緣由。我要讓我的孩子們用雙腳走過孕育他們的這片土地，親眼目睹它的豐富和美麗。

      p 新聞工作是串聯各行各業第一線工作者和社會大眾之間的橋樑。所以新聞工作者必須具有一定的道德感，堅守自己的價值，才能據以發掘問題、過濾訊息、擴大視野、平衡報導。離開報社前，我曾負責過新進記者的培育工作，我看著他們想到以前的自己，希望他們都能尊敬這份職業，並帶上我深切的期許和祝福。

      p 然而新聞工作者的旁觀角色，也讓我覺得「人有一畝田，可以躬耕的踏實和幸福」。這也許是後來我能一無留念地離開新聞界，回家當媽媽，更為孩子投身教改的重要原因吧？

      .ui.divider
      br
      a.bookmark(name="bookmark10")

        p @註十：中國時報是余紀忠先生創辦的。在我任職的十餘年間，從戒嚴到解嚴，報社都高舉著鮮明的自由民主立場。它也是台灣第一個突破百萬份訂戶的最大報紙。沒想到余先生逝世後，他的孩子會把報社（連同報名）一起賣給旺旺集團，成為親中共的媒體。我現在很不願意說我曾在中國時報任事，就是因為繼任者為了金錢，居然背離創報以來的立場轉而屈從威權政體。這樣突兀的變化，對我們這些曾經以報社為榮的自由派新聞工作者，真是難以接受的落差。

        p 余先生的惜才是有名的，他對我頗為欣賞，更對我特立獨行難以攏絡的個性給予各種優容。我常想如果他還在世，對今天轉變立場的中國時報會有什麼感受？

      .ui.divider

      p 1981年長子出生
      p 1985年次子出生
      p 兩個孩子的出生和養育過程，讓我深深體會為人父母的歡喜和無盡的承載。陪伴孩子成長的喜怒哀樂、困頓和希望，我已寫入「成長戰爭」和「天天驚喜」兩書中，孩子幼年時的思維則載入「快子、違禁物品」一書。這三本書後來都被我收回不再發行。主要是孩子們長大了，我得把他們的生命詮釋權歸還給他們。母親觀點的記綠和他們自身所見所感未必相同，因此我停止了這幾本記錄書的發行，讓孩子能夠不受牽連地主導著他們的生活創造和生命詮釋。

      p 雖然我不再談論兩位孩子，但我必須說這兩位孩子真是我此生最大的福緣，他們給了我最深的愛、最大的考驗和最深沉的喜悅。也讓我不得不看到自己性格上的缺失。他們寬容並原諒我因為急切的性格和求全的驕傲，以及我因工作和文化掙扎中，對他們造成的大小傷害。並在彼此成長的過程中，幫助我成為更好的自己。
           
      .ui.divider

      p 1990年我為了孩子，辭掉所有職位，回家當全職媽媽。1992年帶著孩子陪先生在德國見學一年，回國後出版了「天天驚喜」、「快子、違禁物品」。

      p 德國一年，我用自己的眼和心，反省了中西文化之不同，以及不同的教育設計，對孩子可能產生的影響。當時的思考課題都記錄在「天天驚喜」這本書中，孩子的想法也進入「快子、違禁物品」裡。並種下我回台灣後，奮起辦學的因緣。

      
      .ui.divider

      p 1993年參與籌設「毛毛蟲兒童哲學文教基金會」，推動親子閱讀和家庭討論文化。

      
      .ui.divider

      p 1995年與十個家庭共創「種籽親子實驗學苑」（後改制為「種籽親子實驗小學」），把學習的主動權交還給學生，並將家庭納為教育夥伴，提倡親、師、生協立的「自主學習」體制，並成立「兒童實驗文教基金會」承接種籽小學。期間還出版了「種籽手記」、「乖孩子的傷最重」等書籍。

      
      .ui.divider

      p 1999-2007年在北政國中和景文高中主持三屆八年、公辦民營的「自主學習國、高中六年一貫實驗計畫」，將自主學習的學制推到國高中。期間成立了「自主學習促進會」，出版了「北政實驗手記」、「自主學習六講」、「青少年人權手冊」、「異動中的永恆」。

      p 從種籽辦學開始，我們特意把討論文化從家庭推到校園，設計出全校共同參與的「生活討論會」」，並在師生票票等值的討論會中，一條一條制定了我們校園的「生活公約」和每個課堂的「課堂公約」。成立了校園法庭，由師生共選「法官」，公開透明的解決校園的大小爭議。這種由全校師生共治，親師協同的平權化設計，成為後來台灣各民主學校的重要典範。

      p 自主學習學校中，每位教師都有教學自主權、學生有自主學習權，表現出來的是學生選課、選導師權、選法官等制度。課堂中全部採用「學生中心主義」的討論方式進行。加上科別整合的「主題學習」、中學生「必修、選修、自主三學程制」、以及空白課程、社團自主、檢測免修、大旅行等等設計，無一不是色彩鮮明的「另類教育制度性變革」，也為台灣方興未艾的教育改革樹立了一個可供參考的本土性實驗範例。

      p 為了擴展視野，我們還將自己的實做經驗與世界另類教育學校交流，並被聯合國教科文組織的研究學者譽為「亞洲最好的另類教育學校之一」。

      p 後來自主學習中學部因為三屆八年的實驗期滿而結束。小學部則在原址以「教師團治校」的方式，繼續運作。中學部任教的老師有些被政府整編入體制教育，成為巡迴輔導團的教師 ; 有的加入其它另類教育學校或學術單位，成為舉足輕重的成員 ; 更有自辦學校成為創校人的夥伴。這些老師和部份自主中小學畢業的學生，用他們自己的方式在不同領域、不同機緣的情況下，推動著自主學習理念，並持續創造各種可行的作法。

      p 我很喜歡一位夥伴說的:「實驗教育、進步教育並沒有高人一等。請不要給自己莫明奇妙的優越感。回到自己的信念、學生的需要才是最重要的。」
      
      .ui.divider

      p 2007年中學部結束後，我應特教家庭之請，在指南山賃屋為「懼/拒學青少年」辦了「自主培力學園」。積極參與推動「在家自學合法化」，並出版「自學手冊」。在各方努力下，實驗三法終於立法通過後，自主培力學園反而因為房舍不符合機構辦學的要求而停辦了。
      
      .ui.divider

      p 2009年我停辦了自主培力學園，並宣佈退休，回家照顧父母迄今，並且在生活中開始實作陪伴長者的「自主長照」可能。

      p 我之所以退休回家，除了實驗三法通過外，另一個因緣是有一次回家探視父母，道別時回頭一望，發現媽媽神色落寞，行動遲緩。頓然驚覺父母老矣！胡不歸？

      p 我的父母生逢亂世，一生跌盪，撤來台灣，除了孩子，一無所有。我姊姊不幸英年早逝，弟弟舉家在美，能朝夕待奉承歡膝下者唯我一人。於是我回到家和先生認真討論，取得先生的諒解和共識，決定彼此支援，此後各自負擔對親生父母的陪伴之責。

      p 下定決心後，我先將心比心的做了換位思考:「如若我是他們，對女兒退休回家照顧自己，最有可能阻礙他們的觀念是什麼？」

      p 婆家不諒解？~~~這得請先生親自來說明。
      p 成為子女的累贅和麻煩？~~~可用世代推移和類比的方式讓父母釋懷。
      p 不得不依賴他人的自卑？~~~如若以「人皆有老」、「子女有幸再得父母疼愛」的態度相應，或可化解。
      p 增加子女的經濟壓力？~~~父母是公務人員退休，有一定的年金給付，加上子女都有自己的資產，既然套一句老家的說法:「咱有乾柴細米、不漏的房子。」所以經濟不應該是問題。

      p 我想通了: 未來陪伴父母，改善並維持父母的身心健康，才是我要著力的方向。

      p 人都會老，這是自然定律，只不過每個人變老的速度不同，伍子胥過昭關，一夜白頭。傳說彭祖活了八百歲，最後還成了仙。因此，除了身體機能的變化和細胞的更新之外，我相信心理對生理的影響肯定是不爭的事實。

      p 我依照
        a.underline(href="#bookmark11") 自主學習（P-D-C-I）循環
        | 的行動思考來擬定計劃。先勸誘倆老到我的牙醫處，徹底整修好父母的牙齒（我想一個人能吃好吃的東西，應該是生活中的重要樂趣吧！），接著該裝助聽器的裝助聽器（可惜爸媽體質都不適合，換了兩三副都不合用，最後還是靠按摩慢慢回復他們的聽力。）、該用手杖的用手杖、該汰換的衣服鞋襪（棉麻等自然織品肯定有利於皮膚的呼吸）全買來逐步汰換，並且哄著他們打扮得漂漂亮亮的出遊。

      p 一開始我維持父母獨立居住的形態，自己每日來回到父母家「上班」，逐步接手飲食的調理、居住區的清潔和整理工作。並把辦學時「學生中心主義」的教育思惟搬回家裡，開始以父母為中心，和他們共同討論，逐步商量出他們覺得最舒服的生活步調和方式（食、衣、住、行、育、樂各方面）。讓他們覺得他們永遠都會是自己的主人，無需將就任何人。

      p 接著是空間改裝。根據研究，老人跌倒是最大的危機。但是有自主生活空間，對維持老人家的尊嚴還是非常重要的。誰喜歡一天24小時都被人看守呢？因此，只要可能，就儘量利用情境設計（如浴廁、走道裝置各種扶手、使用輔具、移除不必要的傢俱讓客廳變運動場等等），讓老人家能夠安全地自己行動。並且經過陪伴和鼓勵，讓老人家持續自行進食、挑選服裝用具，陪伴盥洗、整理儀容等等，來維持良好的生活品質。

      p 然後是陪著他們走出家門。無論是長青學苑的寫字繪畫唱歌跳舞的課程、逛玉市、逛花市、參觀博物館、賞櫻看菊，只要他們有興趣的、體力許可的，我通通排進行程，並且陪伴同行。

      p 當爸媽體力和出門的興緻隨著年齡日減，我就開始利用社區邊的巷弄長照站、日照中心等長照機構，讓他們能夠認識同儕夥伴，也建立他們規律的生活重心。直到爸爸最後兩年，腦部接受手術取出淤積血塊時，我才請了24小時的看護，協同看顧爸爸。

      p 太極拳的精髓之一是「捨已從人」，我覺得要讓老人家回復對自己生活的掌控信心，照顧者（無論是子女或專業照服員）一定要有「捨己從人」的心理建設。並在這個前提下，以「長者好，我也好」的原則，訂下長照計畫，進行滾動式的調整。這樣才能在照顧長輩的同時，兼顧自己的需求，達到長期相伴，不至耗竭的目標。

      p 另外我發現，陪伴長者和帶孩子最大的不同，是老人家會逐漸失去「自己尋找或創造快樂的能力」。長者不像孩童，一根棒子就可以從孫悟空的金箍棒玩到廚房的桿麵棍，用想像力讓自己具有無限可能。老人家們則是坐等別人想方設法的來娛樂他們，還要挑三揀四的提出各種負向回饋。所以陪伴長者的人，一定要自己能夠快快樂樂的，還要心理強韌，對長者發出的各種負向回應，或奇怪的堅持能夠淡而處之，最好還能開個玩笑轉化過去。三要有點玩耍演戲的心，把陪伴長者當作生活劇場的一部份，這樣大家就能開開心心的一起生活下去了。

      p 因為有營養健康的飲食和量身打造的運動、加上子女在旁的篤定心理、以及尊嚴自主的生活，我的父母逐漸擺脫了高血壓、心臟病、攝護腺腫大、失智等等普遍困擾長者的慢性疾病，不必長期性用藥，而能自在健康的享有晚年時光。

      p 我觀察許多被抱怨「難搞」的長輩，大多是他們的生活自主權被限制或剝奪後的反抗。我覺得老人家像學生（或任何正常的自由人）一樣，都想要自己好好的。所以視人如己，以讚美代替責備，以等待代替催促，以提示代替行動上的取而代之，似乎是面對長者最好的模式。

      p 我常想六、七十歲的自己，還能有父母在堂，真是難得的幸福。三年前當我和弟弟替102歲在家自然去逝的父親擦拭換衣時，看著父親安詳的面容，心中再無遺憾。

      p 目前我和媽媽同住，日日享受九十六歲高齡母親的關心和疼愛，並在父母的蔭庇下，隔離世間的喧嘩。

      p 總之，我想我之所以活著，不是為了什麼偉大的目標，也不是為了不得不然的無奈，而是隨緣流轉，做自己想做的事，並成為想要的自己。我很高興我做到了。這應該也是「我命由我不由天」的意思吧。
      
      .ui.divider
      br
      a.bookmark(name="bookmark11")
        p 註十一：@PDCI循環是我在課堂和學生分享的一種做事流程（從日本PDCA循環修正而得），P是英文plan（計畫）的意思，行動前最好先想清楚，並收集相關資訊，做好計畫，以避免衝動行事。等計畫好了就要D（do）執行計畫，「思而不決」和「決而不行」都無法讓人成功。計畫執行之後要檢視C（chack），從行動中檢視原有計畫未瑧周全之處。然後是I（improve）改進這個計畫，然後再執行，再檢視，再改進，直到成功為止。

        p 這個行事流程和「加分概念」一樣，都是我希望自主學習學生具有的配備之一。

        p 所謂的「加分概念」是相對於我稱之為「減分概念」的傳統求全思考而來。因為我覺得傳統文化中，總是告訴學生「人無完人」、「更好是好的死敵」、「百尺竿頭還要更進一步」，我覺得這都是和理想中的「完美」比較後的不足。我認為人之所以要去學習，就是已經看到自己的不足，如果再懸個假設的完美標準「100分」在那兒，不斷提醒當下只有50分的自己，指責自己還差50分，於是克苦努力再努力，到了60分，還是差了40分。努力到生命終結那天，說不定只能達到80分，看著那無法完成的20分，豈不讓人含恨以終？

        p 「加分概念」則是另一種思惟方式。一開始我們不要騙自己，還是知道自己只有50分，可是怎樣才是100分？其實無人知道，為了讓自己進步，我們還是一樣的努力，看到自己進步了10分，好開心！繼續努力再努力，又進步了2 分、3分，到最後可能也是到了80分，可是整個過程都很快樂，最後可以含笑而去。是不？

      br
      br

      .ui.buttons
        router-link.ui.huge.green.button(to="/story") 故事
        router-link.ui.huge.blue.button(to="/")
          sui-icon(name="home")
          | 回首頁

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '瞭解',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
