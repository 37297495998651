<template lang="pug">
  .hello
    .ui.container.segment
      img.floated.left(src="../assets/water02.jpg")
      
      .ui.header （二）學育潤我

      p 我父母都是1949年隨政府遷台的軍籍人員。他們在二戰時響應國家號召，中斷學業，從軍抗日，並在戰火中僥倖未死。我母親在共產黨渡長江後曾返回家鄉，親眼見到父祖被鬥爭打死。再度離家的母親隨著流亡學生渡海，和最後一批撤離大陸的父親在台灣意外相聚，並在這個小島上赤手空拳地開展了他們人生。我常開玩笑說他們是我們家的「開台祖」。

      p 出身富裕家庭卻必需赤手空拳異地求生的父母，他們對戰亂流亡的認知，建構了我們姊弟三人獨立自主的意志和勇氣。

      p 我爸媽常說千萬家財不如書讀萬卷，一技在身勝過求親靠友。所以我們姊弟三人從小什麼都得學。我媽媽常說:「有一天你們被迫流亡時，能靠知識謀生，就靠知識存活。不能靠知識吃飯時，就算替人幫佣，也得會做菜生火。」

      p 我們知道，媽媽一開始煮魚不知要去鱗，切瓜不懂先去皮，咽不下帶石頭的糙米飯，吞不下發了霉的粗麵饅頭。她常自我鼓勵說:「平平是人，為什麼別人受得了的，我受不了呢？智商30的人都能學會打毛線，我有什麼是學不來的呢？」於是這位從小被捧在手掌心長大的嬌嬌女，從師範學院投軍後，成為馳乘馬背的女騎兵、軍隊的護理士。來台退伍後更轉任小學老師，花了三年時間參加檢定成為中學教師，前後執教了三十五年，極受學生愛戴。直到現在還有學生年年來看她。

      p 我的父母都聰明好強，無論遇到什麼變故，從未在兒女面前嘆過氣、流過淚，節儉克己卻寬厚待人。我從父母身上學會身處逆境時的自尊和優雅，更學會要對現在做規畫，對未來做準備。

      p 另一個讓我終生感謝父母的是他們從不體罰小孩。

      p 或許是富裕人家的底蘊和書香世代的薰陶，「動手打人」這種粗鄙野蠻的行徑從未曾出現在父母的行止中，我戰功豐厚的父親，亦以文雅風流見長。

      p 經商的弟弟曾在父親的追思喪禮中，談到爸爸教給他的是:「要了解處身的世界規則，然後讓規則為我所用。」弟弟據此創造了他的身家和財富。爸爸倒沒有教過我這些，而是
        a(herf="bookmark9") 以身作則的讓我知道「自由人」是什麼樣的？
        | 後來我體會到，自由人是在自己世界中人能夠參與制定規則的人。

      p 父母之外，求學時的老師和辦學時的經歷，也形成了今天的我。

      p 我覺得小學的演講訓練對我幫助極大。那時不知怎麼的（或許是拜姊姊的光環所賜），學校的演講老師對我特別垂青，每每在課後單獨留我下來，從台風、走路到語調運用、手式眼神，細細地指正。我也不負老師的期望，從鄉鎮的國語文競賽（演講、朗讀、作文）中一路過關斬將的走到全國的舞台，也從小學一路風雲開展的走到研究所和跨校交流。我認為公開演講和後來的辯論比賽，確實開展了我的視野，穩定了我的自信，也讓我知道團隊協力的重要，和各種場合中的禮儀。

      p 雖然地處鄉間，但國中幾位老師也影響著青少年轉型期的我。

      p 這些老師大多和我父母一樣，從大陸撤退來台，孓然一身，靠著隨身的一點知識，被分發到各級學校教書。他們知道在時代命運之下，自己是注定被犧牲的一代，但是新生命透過教育，還是有無限的可能，所以他們對自己認定的可造之材，真是盡其所能的傳授、鼓勵、愛護。

      p 我初中一年級的國文老師，每每用端正的小楷密密麻麻地在我的作文簿上圈點回饋，鼓勵我往政治或文學的路上發展 ; 我小學和中學的音樂老師帶我們走入古典音樂的殿堂，訓練我們的發音和吐氣呼吸，教導我們如何運用身體各個部份和聲音相會 ，更在合唱團的訓練中讓我們體會「與眾和聲」的協力與聲部匯集的多元之美 ; 我的家政老師用她綿密的針法養活了自己和一大家人，而在課堂第一次嚐到自製冰淇淋和開口笑等點心的美味，也使我終生喜愛自己動手製作飲食 ; 生物老師開啟了我對身體（包括動植物）的結構和運行能量的讚嘆和情感 ; 連醫務室的護士也肯耐心地教我如何使用各種醫療器械和藥品。我還記得她教我包紮傷口時說的話:「誰知道哪一天戰亂又起，你就可以用這些技巧來救自己和他人了。」

      p 可是她無法預測幸運的我，居然得到近代中國人少有的承平歲月，並享有珍貴的自由、平等和民主。但是威權統治的海峽對岸不斷提醒著我，這樣的幸運並非必然。 我覺得活在台灣的每一個人，都要用智慧和實力來維護這樣的幸福，並為下一代繼續爭取。

      p 大學和研究所的自己，浸在知識的寶庫，揮霍著青春，享受著身體和精神上的自由，直到畢了業，走入社會，承載起組織家長、貢獻社會的責任~~~這些都是讓人興奮的事。

      p 新聞界的工作，特別需要邊做邊學，因為每個路線需要的知識都不同。我每換一個路線第一件事就是組織自己的私房請益名單。十多年的採訪和評論撰寫，讓我有機會接觸不同行業的專家學者，並了解每個領域都有它迷人之處。

      p 辦學的過程更讓我知道「人生而不同，每個生命都有他的獨特與珍貴」，只要有好的啟發和培育，每個人都可以找到適合自己的方式，創造自己的幸福。我更在這段陪伴孩子成長、父母轉化、教師熟成的過程中，不斷回頭整理自己，看清自己的特質，治癒自己的創傷。

      p 今日我終於可以說自己的生命是豐潤的，命運之神對我是慈悲的，而貫穿我整體生涯的相關學習和實作影響，則是滋潤我生命的涓沛甘霖。

      br
      br
      .ui.divider

      br
      a.bookmark(name="bookmark9")
        p 註九：@我父親當年是江蘇徐州城李家樓的二少爺，家裡不但設有工廠釀酒，還印過鈔票。只是家道因打官司和子孫吸食鴨片而中落。抗戰一起，當時高中的父親就投筆從戎，改讀軍校。上了戰場，從抗日到北伐、撤台，父親從未打過敗仗，身上也沒有落下一處傷痕。聽父親說起如何智取軍艦，如何找出喬裝平民的間諜，如何用空城計誘敵等等，簡直比聽人說書還精彩。不過爸爸和我的聊天內容多半是緬甸的黑市、上海的舞廳、湖裡的魚、鴉片之利弊和水鴨絨被、各地小食特產等等風花雪月的記憶。

        p 來台之後，爸爸因為拒絕接受徵召參與諜報工作而受組織懷疑，自動退伍轉任公務員，又被誣陷停職待查，最後終於證明清白的父親常自嘲自己在台灣水土不服，不求進仕，並以低階公務人員身份退休。最後在賞玉、品字畫、玩錢幣等遊戲中度過他的自在晚年。

        p 爸爸自視甚高，不屑和人爭執，我非常喜歡聽爸爸品評政治人物。他的觀察和分析，常讓我有另開一扇窗的感覺。他很早就說民進黨一定會取代國民黨成為台灣的政治主力，也精準預測過美中台之間的關係變化。至於國民黨當年戰敗來台的原因，爸爸更有完全不同於課本的敘述。

        p 爸爸明確認知一個人要享有自由，必須身體、心理、意志、經濟各方面都有一定的基礎，他一生不欠債、不欠情，戒菸是說戒就戒，關係是說斷就斷。晚年攝護腺肥大和後來一度腿癱，都靠道家的氣功方式治癒。當我誇獎爸爸練習時的恆心和毅力時，他只淡淡地說「我總不能連自己都管不好吧？」

        p 確實爸爸一生沒有成為別人的負擔，即使懷才不遇也不懷憂喪志。清明健康地活到102歲高壽才在家自然死亡。他退休後最常說的一句話是「不要管我，讓我自由自在的最好。」而媽媽也確實由著爸爸去做一切他喜歡的事。

        p 爸爸一直很感謝媽媽對她的「縱容」。雖然媽媽認定是她不讓爸爸參與敵後工作，才讓爸爸遭遇後來的打壓。可是爸爸卻認為就因為媽媽拼死不簽字，才讓他們夫妻得以相守一生，否則他會像其他同期被徵召的優秀軍官一樣，有去無回。這種夫妻患難中的彼此感激，讓我的父母恩恩愛愛，白首到老。

      br
      br

      .ui.buttons
        router-link.ui.huge.green.button(to="/story") 故事
        router-link.ui.huge.orange.button(to="/story3") （三）個人年表和記事

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '瞭解',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
