<template lang="pug">
  .hello
    .ui.container
      router-link(to="/h2")
        img.big(v-show = "x == 0", src="../assets/隨緣自強 的副本.jpg")
        img.big(v-show = "x == 1", src="../assets/隨緣自強1.5 的副本.jpg")
        img.big(v-show = "x == 2", src="../assets/隨緣自強2 的副本.jpg")
        img.big(v-show = "x == 3", src="../assets/隨緣自強2.5 的副本.jpg")
        img.big(v-show = "x == 4", src="../assets/隨緣自強3 的副本.jpg")
        img.big(v-show = "x == 5", src="../assets/隨緣自強3.5 的副本.jpg")
        img.big(v-show = "x == 6", src="../assets/隨緣自強4 的副本.jpg")
        img.big(v-show = "x == 7", src="../assets/隨緣自強4.5 的副本.jpg")
        img.big(v-show = "x == 8", src="../assets/隨緣自強5 的副本.jpg")
        img.big(v-show = "x == 9", src="../assets/隨緣自強5.5 的副本.jpg")
      br
      .ui.vertical.buttons.centered.thin-only
        router-link.ui.huge.green.button(to="/about")
          sui-icon(name="calendar")
          | 瞭解更多
        router-link.ui.huge.orange.button(to="/epaper")
          sui-icon(name="edit")
          | 近思新想
      .ui.buttons.centered.fat-only
        router-link.ui.huge.green.button(to="/about")
          sui-icon(name="calendar")
          | 瞭解更多
        router-link.ui.huge.orange.button(to="/epaper")
          sui-icon(name="edit")
          | 近思新想

</template>

<script>

export default {
  name: 'HelloWorld',
  metaInfo: {
    title: '歡迎',
  },
  data: () => ({
    x: 0
  }),
  mounted() {
    setInterval(this.go, 750)
  },
  methods: {
    go () {
      this.x ++;
      this.x = this.x % 10;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main {
  padding: 0;
  max-width: 50vw;
}

.ui.list {
  display: block;
  text-align: left;
  margin-left: 33vw !important;
}

.timeline {
  max-width: 720px !important;
  color: white !important;
  margin: 0 auto;
}

@media screen and (max-width: 420px) {
  .timeline {
    position: relative;
    left: -2em;
  }
  .ui.list {
    margin-left: 15vw !important;
  }
  .sunflower p {
    margin-left: 2vw !important;
  }
}

.be {
  height: 4em;
  border-radius: 30px;
}

.white {
  color: white;
}

.sunflower p {
  margin-left: 15vw;
}

.column .ui.list {
  height: 8em;
  min-height: 0;
}

.column.down {
  padding-top: 2em;
}

.mini {
  height: .8em;
  margin: 0 .2em;
}

.ui.buttons.centered {
  width: 100%;
}

</style>
