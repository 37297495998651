<template lang="pug">
  .hello
    .ui.container.segment
      img.floated.left(src="../assets/water01.jpg")
      .ui.header 網站緣起
      p 24歲法律研究所畢業後，我在新聞界工作了15年（兼社運界義工）。後來為了孩子辭職回家當了兩年全職媽媽，再之後，41歲時，以「父母辦學」為號召，在台灣投入「四一0教改運動」、籌設「種籽親子實驗學苑」，開始「自主學習」的本土教育實驗工程。直到55歲，結束了專為「懼/拒學青少年」成立的「自主培力學園」後退休。這15年期間，我和不同學階的家長、教師們共同協力，以公辦民營的方式，從制度性的國小（種籽親子實驗小學）辦到國、高中（台北市自主學習國、高中六年一貫實驗計畫」。也辦了全自費的學習團體（自主培力學園），更致力於「在家自學法制化」的努力。其間涉及中小學各級學校的課程研發、教案製作、跨領域結合、一般生和特教生的融合教育、家長成長、教師培育、自學指引、制度改革...各方面的實作與反省。

      p 退休後我謝絕了一切邀約，不再過問世事。

      p 雖不像魯迅說的「人一旦悟透了就會變得沈默。不是沒有與人相處的能力，而是沒有了逢人作戲的興趣。」辦學的十五年間，身為首倡者，我為了替心中「自主學習」的理念和做法，爭取一點小小的實作機會，必需不斷地和各級政府、議會、體制學校、社會大眾說明、解釋、遊說、甚至懇求。十五年過去了，這些體制內外所謂的「實驗學生」們也長大了，大家可以從他們身上看到「自主學習理念培養出來的人，是什麼模樣？」之後，我也就喪失再去求人或說明的興緻了。

      p 辦學的十五年，我覺得自己該說的都說了，能做的都做了，過程中可以留下紀錄的也都留了。共事過的夥伴們更從一個個長成的學生身上，證明了無論學生資質如何，教育形態怎樣，「自主學習」確實可以轉化家庭，培養出身心健全、自由創意、懂得協同合作、自立立人的民主公民。身在其中的成人，也變得更柔軟、更自信、更勇敢、更懂得愛與被愛。因此，他們和部份學生接下了棒子，開始在各個層面，推廣著我們協同創發的「自主學習」內涵~~~一種生命態度、理念和各種各樣具體可行的實踐和創新作法。

      p 回家侍奉父母的我，一方面開始了另一段「自主長照」的安靜生活，二方面欣慰地看著在不同人的努力下，「自主學習」漸漸成為台灣教育界的主流，還進入國家108課綱，成為自發、互動、共好的三大原則。體制內外各種根源於此的相關實作如雨後春筍般出現。同時我也看著傳統慣性教育對它的抗拒和反彈。以及各種打著「自主學習」之名行「操控」、「惡性競爭」或「放任」之實的混亂形態。

      p  隨著2019年香港的時代革命與2022年烏克蘭的戰火，安坐家中的我，聽著日日盤旋在台灣周邊的中共戰機和島內各種或戰或和的爭議聲，想到「古之聖人，其和愉寧靜，性也；其志得道行，命也。」既然自由、民主、和平、多元、協同、共好等等自己持守的理念，並不是人類發展的必然，於是想起過去打拼的歲月和一起努力的夥伴，而興起與外界聯結的念頭。或者因此能讓自己再有「性遭命而後能行」的機緣，並創「命得性而後能明」的歡喜。

      p 由於自己已列頤養之年，實不願對承載現實世界的年輕人指手畫腳，所以學著使用網路工具，一方面慢慢整理自己的生命歷程和目前的「自主長照心得」，另方面想著怎樣才能水波不興地實現
        a.underline(href="#bookmark1") 個人與世界有限的溝通？
        | 於是有了這個小小個人網站的出現。

      a(name="bookmark1")
        p 註一：@有限的聯結與溝通，指的是在我可行的時間內，用我可行的方式（文字），分享我可以分享的心得。

      p 除了新聞工作經驗外，我做過的事有:

      ol
        li 小學到高中的自主學習引導（包括學校形態及在家自學）
        li 父母成長工作坊
        li 同理心工作坊（同理回饋劇/社群/自我成長）
        li 帶領太極導引團練
        li 自主生活60+
        li 個人層面的環保生活

      p 未來我想嘗試或深入學習的有:

      ol
        li 哲學論壇與實作（自由民主、護生共好....等價值觀的整理與實作）
        li 數位學習
        li 正向心理學的探索與實作
        li 農作（自然農法）
        li 理財（股市及其它）
        li 小說（成人、青少年）、劇作、兒童繪本創作等等
        li 繪畫（國書和西畫）創作
        li 書法練習

      .ui.buttons
        router-link.ui.huge.green.button(to="/story")
          sui-icon(name="user")
          | 我的故事
        router-link.ui.huge.blue.button(to="/")
          sui-icon(name="home")
          | 回首頁

</template>

<script>

export default {
  name: 'AboutWorld',
  metaInfo: {
    title: '瞭解',
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  li {
    font-size: 18px;
    line-height: 1.8;
  }

</style>
