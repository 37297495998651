import { render, staticRenderFns } from "./Book1.vue?vue&type=template&id=462df3a5&scoped=true&lang=pug&"
import script from "./Book1.vue?vue&type=script&lang=js&"
export * from "./Book1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462df3a5",
  null
  
)

export default component.exports