<template lang="pug">
  .hello
    .ui.container.segment
      img.floated.left.small(src="../assets/water03.jpg")
      .ui.header 近思新想

      br
      br

      .ui.divider

      br

      .ui.divided.list.container
        .item(v-for = "k in r(docs)")
          a.underline(:href="k.href", target="_blank", rel="noopener noreferrer")
            .ui.header {{k.date}}: {{k.title}}

      br

      .ui.divider

      p 註：人的思想和情境總在流動中，現代的社交平台提供了一個方便了解他人近況的機會，只是這些內容常淪於個人的起居注。為了避免自己陷此情境，覺得使用E-mail群組只和朋友們互動，是個好主意。

      .ui.divider

      iframe(src="https://docs.google.com/forms/d/e/1FAIpQLSexAyzBhyBzE6NjljS-BIk2JAgV8a3Rxoko8ZVq-rRUv_JwiQ/viewform?embedded=true" width="640" height="706" frameborder="0" marginheight="0" marginwidth="0") 載入中…

      .ui.divider

      .ui.buttons.centered
        router-link.ui.huge.green.button(to="/about")
          sui-icon(name="home")
          | 回首頁
        router-link.ui.huge.orange.button(to="/talk")
          sui-icon(name="comments")
          | 交流

</template>

<script>
// import { docsRef } from '../firebase'
import { db } from '../firebase.js'
import { ref, onValue } from 'firebase/database'

/*
function csvToJSON(csv) {
    var lines = csv.split("\"\n");
    var result = [];
    var headers;
    headers = lines[0].replace(/"/g, '').split(",");

    for (var i = 1; i < lines.length; i++) {
        var obj = {};

        if(lines[i] == undefined) {
            continue;
        }

        if (lines[i].match(/subject.*sharing/)) {
          obj['raw'.trim()] = lines[i].match(/subject.*sharing/)[0];
          obj['date'.trim()] = lines[i].match(/202.*Z/)[0];
          obj['title'.trim()] = lines[i].match(/「.*」/)[0];
          obj.title = obj.title.replace(/「|」/g,'');
          obj['href'.trim()] = obj.raw.match(/http.*sharing/)[0];
        } else {
          var words = lines[i].split(",");
          for(var j = 0; j < words.length; j++) {
              obj[headers[j].trim()] = words[j];
          }
        }

        obj.date = obj.date.replace(/T.*$/, '').replace(/"/g,'');

        if (obj.date2 && obj.date2 != "\"") {
          obj.date = obj.date2.replace(/T.*$/, '').replace(/"/g,'');
        }

        console.log(obj);

        if (obj.raw && obj.title == "\"\"") {
          obj.title = obj.raw.match(/「.*」/)[0];
          console.log(obj.title);
          obj.title = obj.title.replace(/「|」/g,'');
          obj.href = obj.raw.match(/http.*sharing/)[0];
        }

        obj.title = obj.title.replace(/"/g,'');
        
        result.push(obj);
    }
    console.log(result);
    return result;
} */

export default {
  name: 'EPaper',
  metaInfo: {
    title: '近思新想',
  },
  data: () => ({
    list: [

    { title: '我狗阿黃', date: '2022/10/07', href: 'https://docs.google.com/document/d/1IcZSx_yikIf3_MnshC6nK83RMl4U8PcsRx6oxydJ5hA/?usp=sharing'},
    { title: '民主、多元與包容、鄉愿', date: '2022/09/22', href: 'https://docs.google.com/document/d/16QHVl3jDYHeI5jLCdq8vzlydd1CFr1koZP_8EHGSnyU/?usp=sharing'},
    { title: '先行者的論述困境', date: '2022/09/09', href: 'https://docs.google.com/document/d/1KQy2chDFHrgo0eIyOBAzNDYQVuI4G1B2XDFh67xX960/?usp=sharing'},
      { title: '回歸本質~~~從論文風波和政治體制說起', date: '2022-08-26', href: 'https://docs.google.com/document/d/1hPJGBI31NIRJdpqeapmGh2qd9zD0fNQprELm2CuWlAU/edit?usp=sharing'},
      { title: '網路學習驚魂記', date: '2022-08-12', href: 'https://docs.google.com/document/d/1tHE5OzkUr5dNX5-ZUaeBIrM5rTDU8Te_DBCuV4CQTfs/edit?usp=sharing'},
      { title: '賦予山、河、大自然人格權吧！', date: '2022-07-29', href: 'https://docs.google.com/document/d/1ec-SGgG1Qn5iFWpUM6kjWeyaKMyQ-5PXIaGOxH-Syqk/edit?usp=sharing'}
    ],
    docs: []
  }),
  mounted () {
    const vm = this
    onValue(ref(db, 'docs'), (snapshot) => {
      const data = snapshot.val()
      vm.docs = data
    })
    /* vm.$http.get('https://docs.google.com/spreadsheets/d/1q4_p2IIpnOUPM2aVZioNG-rZnvGYkO3IDPCFTf8Q4VE/gviz/tq?tqx=out:csv&sheet=docs').then((response) => {
        vm.docs = csvToJSON(response.data);
        vm.docs.reverse();
    }) */
  },
  methods: {
    r (list) {
      var ans = [...list]
      ans.sort(function (a, b) {
        return (new Date(b.date).getTime() - new Date(a.date).getTime())
      })
      return ans
    }
  }
//  firebase: {
//    docs: docsRef
//  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #right {
    margin-left: 3em;
  }

  img.small {
    width: 60px !important;
    max-width: 50%;
    border-radius: 5px;
  }

  img.ui.avatar {
    width: 50px;
    border-radius: 25px;
  }

</style>
